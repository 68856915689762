import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { styles } from '../../styles/themes/style'
import Row from '../../components/Row'

interface IEditDeleteActionDropdownProps {
    type: string,
    object: Object,
    className?: string,
    onEditHandler: (e: any) => void
    onDeleteHandler: (e: any) => void
}

const EditDeleteActionDropdown: FunctionComponent<IEditDeleteActionDropdownProps> = ({ className, type, object, onEditHandler, onDeleteHandler }) => {
    const editDeleteActions = [
        {
            icon: 'pen',
            title: `Edit ${type}`,
            slug: 'edit',
            enabled: true,
            visible: true,
            onClickHandler: () => onEditHandler && onEditHandler(object)
        },
        {
            icon: 'trashCan',
            title: `Delete ${type}`,
            slug: 'delete',
            enabled: true,
            destructive: true,
            visible: true,
            onClickHandler: () => onDeleteHandler && onDeleteHandler(object)
        }
    ]
    return <DropdownMenu className={className} SVGtype='threeDots' $hideToggle $transparent tooltipMessage={'Actions'}>
        {editDeleteActions.map((editDeleteAction, index) =>
            <DropdownItem key={index} $danger={editDeleteAction.destructive} onClickHandler={() => editDeleteAction.onClickHandler && editDeleteAction.onClickHandler()}>
                <Row alignItems gap="5px"><SvgFragment type={editDeleteAction.icon} />{editDeleteAction.title}</Row>
            </DropdownItem>)}
    </DropdownMenu>
}

export default EditDeleteActionDropdown
