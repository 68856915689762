import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TagControlBar from '../components/tags/TagControlBar';
import TagTable from '../components/tags/TagTable';

interface ITagSectionProps { }

const TagSection: FunctionComponent<ITagSectionProps> = () => {

    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

    return <Container>
        <Column>
            <TagControlBar selectedTags={selectedTags} setSelectedTags={setSelectedTags} isAllSelected={isAllSelected} setIsAllSelected={setIsAllSelected} />
            <TagTable selectedTags={selectedTags} setSelectedTags={setSelectedTags} isAllSelected={isAllSelected} setIsAllSelected={setIsAllSelected} />
        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

export default TagSection
