import React, { useEffect, useState } from 'react';
import './App.scss';
import { themes } from './styles/themes/style';
import styled, { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import AppModal from './components/modal/AppModal';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './wrappers/AppRouter';
import { styles } from './styles/themes/style';
import 'bootstrap/dist/css/bootstrap.css';
import Toast from './components/Toast';
import Spinner from './components/Spinner';
import { useAppStore } from './stores/application/useAppStore';
import Reset from './styles/Reset';
import { analytic, getPageTitle, getPageUrl } from './utils';
import AppContainer from './pages/AppContainer/AppContainer';
import { useAppHandlers } from './services/actions/app/useAppHandlers';
import Sidebar from './components/Sidebar';
import { ISidebar } from './interfaces/store/appStore';
import SidebarRender from './fragments/SidebarRender';

function App() {
  const { store: { spinner, extensionId, sidebar, darkMode } } = useAppStore()
  const { checkExtensionStatus, closeSidebar, hideModal } = useAppHandlers()
  const [url, setUrl] = React.useState(window.location.href);

  const refs = {
    previousUrl: React.useRef<any>(''),
    url: React.useRef<any>(window.location.href)
  };

  useEffect(() => {
    const observer = new MutationObserver(async (mutations) => {
      mutations.forEach(async (mutation) => {
        if (refs.url.current !== window.location.href) {
          refs.url.current = window.location.href;

          setUrl(window.location.href);
        }
      });
    });

    const config = {
      childList: true,
      subtree: true
    };

    observer.observe(window.document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);
  

  useEffect(() => {
    checkExtensionStatus();
  }, [extensionId])

  useEffect(() => {
    // analytic 
    analytic({
      event: 'virtualPageview',
      pageUrl: getPageUrl(),
      referrer: refs.previousUrl.current,
      pageState: '',
      pageTitle: getPageTitle()
    });

    // Previous url 
    refs.previousUrl.current = url;
  }, [url]);

  return (
    <ThemeProvider theme={themes[darkMode ? 'dark' : 'light']}>
      <Reset />

      <BrowserRouter>
        <AppContainer>
         {!!sidebar.length  && sidebar.map((sidebarItem: ISidebar) => 
              <Sidebar zIndex={sidebarItem.zIndex} onClose={() => {
                if(typeof sidebarItem.type === 'string' && ['connectionActions'].includes(sidebarItem.type)) hideModal()
                closeSidebar(sidebarItem.type)
              }}>
                <SidebarRender key={sidebarItem.zIndex} sidebarType={sidebarItem.type} {...sidebarItem.props}/>
              </Sidebar>)}
          <Header />
          <PagesContainer>
            <AppRouter />
          </PagesContainer>
          <AppModal />
          <Toast />
          <Spinner showHideSpinner={spinner} />
        </AppContainer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const PagesContainer = styled.div`
    width: 100%;
    height: calc(100% - ${styles.lengths.navbarHeight});
    overflow-y: auto;
`

export default App;
