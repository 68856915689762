import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
//import TabsNameInput from '../Inputs/TabsNameInput'
//import LDTooltip from 'components/LDTooltip/LDTooltip';
import Input from "./Input";
import TabsView, { ITabProperties } from "./TabsView";
import Text from "./Text";

interface ITabsSearchSelectProps {
  tabs: ITabProperties[];
  infoTitle: string;
  searchByNameValue?: string;
  children: ReactNode;
  overflow?: string;
  resetDisabled?: boolean;
  searchByNameHandler?: (e: any) => void;
  setSelectedTab: (e: any) => void;
  resetSelectedValues: (e: any) => void;
}


interface IListChildrenStyledProps {
  overflow?: string;
}

const TabsSearch: FunctionComponent<ITabsSearchSelectProps> = ({
  tabs,
  infoTitle,
  searchByNameValue,
  searchByNameHandler,
  setSelectedTab,
  resetDisabled,
  resetSelectedValues,
  children,
  overflow = "true"
}) => {

  return (
    <>
      {searchByNameHandler && (
        <Input placeholder="Search by name" onChangeHandler={(e: any) => searchByNameHandler(e)} />
      )}
      <TabsView infoTitle={infoTitle} tabs={tabs} onSwitchTab={(e) => setSelectedTab(e)} onReset={(e: any) => resetSelectedValues(e)}></TabsView>
      <ListChildren overflow={overflow}>{children}</ListChildren>
    </>
  );
};
const ListChildren = styled.div<IListChildrenStyledProps>`
  max-height: 224px;
  ${(props) => props.overflow && "overflow: auto"};
`;

export default TabsSearch;
