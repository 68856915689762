import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import { useConnectionsStore } from '../../../../stores/connections/useConnectionsStore'
import Row from '../../../../components/Row'
import { useConnectionsHandlers } from '../../../../services/actions/connections/useConnectionsHandlers'
import FilterButton from '../../../../fragments/FilterButton'
import { AppRoutes, SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { Navigate, useNavigate } from 'react-router-dom'
import ConnectionSearchFilter from '../../../../fragments/ConnectionSearchFilter'
import { useSaveFilterHandlers } from '../../../../services/actions/save-filters/useSaveFilterHandlers'
import ConnectionCustomizeColumns from '../../../../fragments/ConnectionCustomizeColumns'
import { IConnection } from '../../../../interfaces/models/connection'
import BulkConnectionActionsDropdown from '../../../../fragments/dropdowns/BulkConnectionActionsDropdown'
import Column from '../../../../components/Column'

interface IConnectionControlBarProps { 
    isMobile?: any,
    isSmall?: any,
    selectedConnections: IConnection[],
    selectedAllConnections?: boolean
}

interface IRowStyle {
    showMobile?: boolean
}

const ConnectionControlBar: FunctionComponent<IConnectionControlBarProps> = ({selectedConnections, selectedAllConnections}) => {
    const navigate = useNavigate()

    const { store: { connectionsFilter, connectionsParameters }} = useConnectionsStore()
    const { page, pageSize, total } = connectionsParameters
   
    const { setConnectionsFilterHandler, setConnectionsParametersHandler, sendMessageToConnectionsHandler, exportToCSVHAndler, numberOfConnectionsFiltersHandler, setClearConnectionsFilterHandler, getConnectionsHandler, getMultipleConditionsFiltersHandler } = useConnectionsHandlers()
    const { openSidebar, closeSidebar, setExportConnectionsModalHandler } = useAppHandlers()
    const { getSavedFiltersHandler } = useSaveFilterHandlers()

    const [savedFilters, setSavedFilters] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    useEffect(() => {
        getConnectionsHandler();
        getMultipleConditionsFiltersHandler();
    }, [connectionsFilter, page, pageSize ]);

    useEffect(() => {
        getSavedFilters()
    }, [connectionsFilter.savedFilter])

    const getSavedFilters = async () => {
        const result = await getSavedFiltersHandler()
        setSavedFilters(result ?? [])
    }

    const exportConnections = useCallback(async (includeTags: boolean, includeNotes: boolean, exportAll: boolean) => {
        if(exportAll){
            await exportToCSVHAndler(includeTags, includeNotes)
        }
        else {
            await exportToCSVHAndler(includeTags, includeNotes, selectedConnections)
        }
    }, [selectedConnections, connectionsFilter]) 

    const searchHandler = useCallback((e: any) => {
        console.log(e);
        
        const updatedFilter = {
            ...connectionsFilter,
            searchValue: e,
            searchByName: 'yes', // TODO
        };
        
        setConnectionsFilterHandler(updatedFilter);
    }, [connectionsFilter, setConnectionsFilterHandler]); 


    const isCsvConnection = (connection: IConnection) => {
        const hasCSVOrImportedTag = connection.tags?.some(item => item.title === 'CSV' || item.title === 'Imported');
        const hasLinkedinTag = connection.tags?.some(item => item.title === 'LinkedIn' || item.title === 'LinkedIn 1st' || item.title === 'Disconnected');
    
        return hasCSVOrImportedTag && !hasLinkedinTag;
    };

    const resetFilterHandler = useCallback(() => {
        setClearConnectionsFilterHandler();
    }, [connectionsFilter, setConnectionsFilterHandler]);

    return <Row gap='20px' alignItems spaceBetween flexWrap>
        <ShowMobile>
            <StyledRow gap="12px" showMobile>
                <ConnectionSearchFilter />
            </StyledRow>
        </ShowMobile>
        <Row gap="10px">
            <Button $type='white' disabled={selectedConnections?.length === 0 || selectedAllConnections} $mediumBigButton $SVGtype='message' onClickHandler={() => openSidebar(SidebarTypes.BULK_MESSAGE, { formType: 'message', onMessageSaveHandler: (message: string) =>  sendMessageToConnectionsHandler(selectedConnections.filter(item => !(item?.imported || item?.notConnectedLinkedin || isCsvConnection(item))), message)} )}>{'Message'}</Button>
            <Button $type='white' disabled={selectedConnections?.length === 0} $mediumBigButton $SVGtype='label' onClickHandler={() => openSidebar(SidebarTypes.APPLY_TAG, { selectedAllConnections: selectedAllConnections, selectedConnectionsIds: selectedConnections?.map(connection => connection._id), onSaveHandler: () => closeSidebar(SidebarTypes.APPLY_TAG) })}>{'Apply tags'}</Button>
            <BulkConnectionActionsDropdown selectedConnections={selectedConnections} selectedAllConnections={selectedAllConnections}/> 
            <Divider />
            <StyledRow gap='10px'>
                <Button $type='white' $mediumButton $SVGtype='import' onClickHandler={() => navigate(AppRoutes.CSV_IMPORT)}>{'Import'}</Button>
                <Button $type='white' disabled={total === 0} $mediumButton $SVGtype='export' onClickHandler={() => setExportConnectionsModalHandler(exportConnections, selectedAllConnections ? total : selectedConnections?.length ?? undefined, selectedAllConnections)}>{'Export'}</Button>
            </StyledRow>
        </Row>
        <Row gap="12px">
            <Row gap='12px'>
                <TablePageNavigation 
                    page={page ?? 1}
                    pageSize={pageSize ?? 25}
                    totalCount={total ?? 0}
                    onPageChanged={(value: number) => setConnectionsParametersHandler({...connectionsParameters, page: value})}
                    onPageSizeChanged={(value: number) => setConnectionsParametersHandler({...connectionsParameters, pageSize: value})}
                />
            </Row>
            <StyledRow gap="12px">
                <ConnectionSearchFilter />
            </StyledRow>
            <Row gap="12px">
                <FilterButton
                    sidebar={SidebarTypes.CONNECTION_FILTER}
                    savedFilters={savedFilters}
                    numberOfFilters={numberOfConnectionsFiltersHandler()}
                    onSetSavedFilter={(savedFilter: any) => setConnectionsFilterHandler({...connectionsFilter, ...savedFilter.filters})}
                    resetFilter={() => resetFilterHandler()}
                />
                <StyledRow>
                    <ConnectionCustomizeColumns />
                </StyledRow>
            </Row>
        </Row>
    </Row>
}

const Divider = styled.div`
    height: 34px;
    width: 1px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};

    @media (max-width: 575px) {
        display: none;
    }
`
const StyledRow = styled(Row)<IRowStyle>`
    ${({showMobile}) => !showMobile && `
        @media (max-width: 575px) {
            display: none;
        }
    `}
`
const ShowMobile = styled(Row)<IRowStyle>`
    display: none;

    @media (max-width: 575px) {
        display: block;
    }
`
export default ConnectionControlBar