import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IConnection } from '../../interfaces/models/connection'
import { useExternalHandlers } from '../../services/actions/navigation/useExternalHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import { SidebarTypes, TMenuItemState } from '../../interfaces/store/appStore'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import DropdownItem from '../../components/dropdown/DropdownItem'
import SvgFragment from '../SvgFragment'
import { styles } from '../../styles/themes/style'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import Row from '../../components/Row'
import { useAuthStore } from '../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'

interface IConnectionActionDropdownProps { 
    connection: IConnection
}

const ConnectionActionDropdown: FunctionComponent<IConnectionActionDropdownProps> = ({connection}) => {
    const { visitLIProfile } = useExternalHandlers() //get these from the above
    const { openSidebar, openExtension, setDisconnectConnectionsModalHandler } = useAppHandlers()
    const { store: { user } } = useAuthStore()
    const { store: { extensionInstalled } } = useAppStore()
    const { updateConnectionHandler, followProfileHandler, unfollowProfileHandler, toggleHideConnectionHandler, disconnectConnectionHandler } = useConnectionsHandlers()
    //some of them are for the sidebar and some are for the table, pay attention

    const [enableLinkedinAction, setEnableLinkedinAction] = useState<boolean>(false)

    useEffect(() => {
        const version = connection?.users?.filter((e: any) => e?._id === user?._id )
        setEnableLinkedinAction(version?.[0]?.version === 'in-1st')
    }, [connection])

    const updateProfile = useCallback(async () => {
        const result = await updateConnectionHandler(connection)
        if(result) connection = result
    }, [connection])

    const followProfile = useCallback(async () => {
        const result = await followProfileHandler(connection)
        if(result) connection = result //This should be state
    }, [connection])

    const unfollowProfile = useCallback(async () => {
        const result = await unfollowProfileHandler(connection)
        if(result) connection = result //This should be state
    }, [connection])
    const toggleHideUnhide = useCallback(async (value: boolean) => {
        const result = await toggleHideConnectionHandler(connection, value)
        if(result) connection = result //This should be state
    }, [connection])
    const disconnectRemove = useCallback(async (disconnectFromLI: boolean, removeFromLD: boolean) => {
        await disconnectConnectionHandler(connection, disconnectFromLI, removeFromLD);
    }, [connection]) 
    

    const connectionActionsInitial = [
        {
            icon: 'profile',
            title: 'Visit profile',
            slug: 'visitProfile',
            enabled: connection.publicIdentifier,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.publicIdentifier && visitLIProfile(connection.publicIdentifier)
        },
        {
            icon: 'message',
            title: 'Message',
            slug: 'messageProfile',
            enabled: extensionInstalled && connection.publicIdentifier && enableLinkedinAction,
            visible: true,
            onClickHandler: (connection: IConnection) => openExtension({ query: `redirectTo=inbox&messagedConnection=${connection._id}`, newTab: false })
        },
        {
            icon: 'refresh',
            title: 'Update Profile',
            slug: 'updateProfile',
            enabled: extensionInstalled && connection.profileId,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.profileId && updateProfile()
        },
        {
            icon: 'label',
            title: 'Apply/Remove tags',
            slug: 'applyRemove',
            enabled: true,
            visible: true,
            onClickHandler: (connection: IConnection) => openSidebar(SidebarTypes.APPLY_TAG, { connection })
        },
        {
            icon: 'profileAdd',
            title: 'Follow',
            slug: 'follow',
            enabled: connection.publicIdentifier && enableLinkedinAction,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.profileId && followProfile()
        },
        {
            icon: 'profileRemove',
            title: 'Unfollow',
            slug: 'unfollow',
            enabled: connection.publicIdentifier && enableLinkedinAction,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.profileId && unfollowProfile()
        },
        {
            icon: 'hide',
            title: 'Hide',
            slug: 'hide',
            enabled: true,
            visible: !connection.isHidden,
            onClickHandler: (connection: IConnection) => connection && toggleHideUnhide(true)
        },
        {
            icon: 'show',
            title: 'Unhide',
            slug: 'unhide',
            enabled: true,
            visible: connection.isHidden ?? false,
            onClickHandler: (connection: IConnection) => connection && toggleHideUnhide(false)
        },
        {
            icon: 'profileRemove',
            title: 'Disconnect / Remove',
            slug: 'remove',
            enabled: true,
            visible: true,
            destructive: true,
            onClickHandler: (connection: IConnection) => setDisconnectConnectionsModalHandler(disconnectRemove, undefined, undefined, undefined, enableLinkedinAction)
        },
    ]

    useEffect(() => {
        setConnectionActions(connectionActionsInitial)
    }, [connection])

    const [ connectionActions, setConnectionActions ] = useState<TMenuItemState[]>(connectionActionsInitial);
    //todo: further adjustment needed, on click handling states depending on the imported state etc.

    return <DropdownMenu SVGtype='threeDots' $hideToggle tooltipMessage={'Actions'}>
        {connectionActions.map(connectionAction => 
            connectionAction.visible && <DropdownItem key={connectionAction?.title} $disabled={!connectionAction.enabled} $danger={connectionAction.destructive} onClickHandler={() =>  connectionAction.onClickHandler && connectionAction.onClickHandler(connection)}>
                <Row alignItems gap="5px"><SvgFragment type={connectionAction.icon}/>{connectionAction.title}</Row>
            </DropdownItem>)}
    </DropdownMenu>
}

export default ConnectionActionDropdown
