import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import NotesControlBar from '../components/notes/NotesControlBar';
import NotesTable from '../components/notes/NotesTable';
import { useNotesHandlers } from '../../../services/actions/notes/useNotesHandlers';
import { useNotesStore } from '../../../stores/notes/useNotesStore';

interface INoteSectionProps { }

const NoteSection: FunctionComponent<INoteSectionProps> = () => {

    const { getNotesHandler } = useNotesHandlers()

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

    const { store: { notes, notesFilters, notesParameters }} = useNotesStore()

    useEffect(() => {
        getNotesHandler()
    }, [notesParameters.page, notesParameters.pageSize, notesFilters]) //dule: another reason to use one variable, this is silly

    return (
        <Container>
            {(
                <Column>
                    <NotesControlBar
                        isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                        notesFilters={notesFilters}
                        notesParameters={notesParameters}
                    />
                    <NotesTable
                        isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                        notes={notes}
                        notesFilters={notesFilters}
                    />
                </Column>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

export default NoteSection
