import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { styles } from '../../styles/themes/style'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../interfaces/store/appStore'
import { IConnection } from '../../interfaces/models/connection'
import Row from '../../components/Row'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import { useAnalyticsHandlers } from '../../services/actions/analytics/useAnalyticsHandlers'
import { useAuthStore } from '../../stores/auth/useAuthStore'

interface IBulkConnectionActionsDropdownProps {
    className?: string,
    selectedConnections?: IConnection[],
    selectedAllConnections?: boolean
}

const BulkConnectionActionsDropdown: FunctionComponent<IBulkConnectionActionsDropdownProps> = ({ className, selectedConnections, selectedAllConnections }) => {

    const { openSidebar, closeSidebar, setDisconnectConnectionsModalHandler } = useAppHandlers()
    const { toggleBulkHideConnectionsHandler, bulkDisconnectConnectionHandler } = useConnectionsHandlers()
    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers()
    const { store: { user } } = useAuthStore()

    const [disconnectedConnectionsCount, setDisconnectedConnectionsCount] = useState<any>(0)
    const [enableLinkedinAction, setEnableLinkedinAction] = useState<boolean>(false)

    const connectionsConnectedToLinkedin = selectedConnections?.filter(item => !(item?.imported || item?.notConnectedLinkedin)); 
    const newDisconnectedCount = connectionsConnectedToLinkedin?.length + disconnectedConnectionsCount

    useEffect(() => {
        getTotalDisconnected()
    }, [])

    useEffect(() => {
        if(selectedConnections?.length === 1) {
            const version = selectedConnections?.[0]?.users.filter((e: any) => e?._id === user?._id)
            setEnableLinkedinAction(version?.[0]?.version === 'in-1st')
        } else { 
            setEnableLinkedinAction(true)
        }
    }, [selectedConnections])

    const getTotalDisconnected = async () => {
        const result = await getTotalConnectionsOverviewAnalytics()
        setDisconnectedConnectionsCount((result.find(item => item.version === 'connections-disconnected-total'))?.value || 0)
    }

    const disconnectRemove = useCallback(async (disconnectFromLI: boolean, removeFromLD: boolean) => {
        if(selectedConnections){
           await bulkDisconnectConnectionHandler(selectedConnections, disconnectFromLI, removeFromLD, selectedAllConnections ?? false);
        }
    }, [selectedConnections, selectedAllConnections]) 

    const bulkConnectionActions = [
        {
            icon: 'crossedLabel',
            title: `Remove Tags`,
            slug: 'removeTags',
            enabled: true,
            visible: true,
            destructive: true,
            onClickHandler: () => openSidebar(SidebarTypes.REMOVE_TAG, { selectedAllConnections, selectedConnectionsIds: selectedConnections?.map(connection => connection._id), onSaveHandler: () => closeSidebar(SidebarTypes.REMOVE_TAG) })
        },
        {
            icon: 'profileRemove',
            title: `Disconnect/Remove`,
            slug: 'disconnect',
            enabled: true,
            visible: true,
            destructive: true,
            onClickHandler: () => setDisconnectConnectionsModalHandler(disconnectRemove, newDisconnectedCount, selectedAllConnections, selectedConnections?.length ?? undefined, enableLinkedinAction)
        },
        {
            icon: 'hide',
            title: `Hide`,
            slug: 'hide',
            enabled: true,
            visible: true,
            destructive: true,
            onClickHandler: () => toggleBulkHideConnectionsHandler(selectedConnections, true)
        },
        {
            icon: 'show',
            title: `Unhide`,
            slug: 'unhide',
            enabled: true,
            visible: true,
            destructive: false,
            onClickHandler: () => toggleBulkHideConnectionsHandler(selectedConnections, false)
        }
    ]

    return <DropdownMenu tooltipMessage={'More actions'} disabled={selectedConnections?.length === 0} className={className} title={<SvgFragmentWrapper><SvgFragment type='threeDots' /></SvgFragmentWrapper>} $hideToggle>
        {bulkConnectionActions.map((connectionAction, index) =>
            <DropdownItem $disabled={!connectionAction.enabled} key={index} $danger={connectionAction.destructive} onClickHandler={(e: any) => connectionAction.onClickHandler && connectionAction.onClickHandler()}>
                <Row alignItems gap="5px"><SvgFragment type={connectionAction.icon} />{connectionAction.title}</Row>
            </DropdownItem>)}
    </DropdownMenu>
}

const SvgFragmentWrapper = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
`


export default BulkConnectionActionsDropdown
