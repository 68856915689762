import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import CreatableAsyncSelect from '../../components/select/CreatableAsyncSelect'
import ApplyRemoveTagsSelect, { IEditableTag } from '../ApplyRemoveTagsSelect'
import { IConnection, ITag } from '../../interfaces/models/connection'
import Button from '../../components/Button'
import { IFormProperty } from '../../interfaces/common'
import ActionText from '../../components/ActionText'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import Row from '../../components/Row'

interface IApplyRemoveTagFormProps {
    submitClicked?: boolean,
    connection?: IConnection,
    selectedConnectionsIds?: string[],
    selectedAllConnections?: boolean,
    inline?: boolean
    tags: ITag[],
    onSaveHandler?: (e: any) => void
    onCancelHandler?: () => void
    onChangeHandler?: (e: any) => void
 }

const ApplyRemoveTagForm: FunctionComponent<IApplyRemoveTagFormProps> = ({connection, selectedConnectionsIds, selectedAllConnections, tags, submitClicked, inline, onSaveHandler, onCancelHandler, onChangeHandler}) => {
    const [value, setValue] = useState<IEditableTag[]>([])
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const { applyRemoveTagsToConnection, bulkApplyTags } = useConnectionsHandlers()
    const { store: { requestLoading } } = useAppStore()

    useEffect(()=>{
        if(!!submitClicked) onSubmitHandler()
    }, [submitClicked])

    useEffect(() => {
        if('bulkApplyTag' in requestLoading) setSubmitLoading(requestLoading?.['bulkApplyTag'])
    }, [requestLoading]);

    const onSubmitHandler = useCallback(async (event?: FormEvent) => {
        if(event) event.preventDefault()
        setSubmitLoading(true)
        let applyTags: any[] = [] 
        let removeTags: any[] = []
        applyTags = value.filter(item => !tags.some((tag => tag.title === item.title)));
        if(connection) removeTags = tags.filter(item => !value.some((tag => tag.title === item.title)));

        applyTags = applyTags.map(tag => tag._id ?? { title: tag.title, private: tag.private, color: tag.color })
        removeTags = removeTags.map(tag => tag._id)

        if(selectedAllConnections){
            const result = await bulkApplyTags(applyTags, [])
            onSaveHandler && onSaveHandler(result)
        }
        else if(connection){
            const newConnection = await applyRemoveTagsToConnection(connection._id, applyTags, removeTags)
            onSaveHandler && onSaveHandler(newConnection)
        }
        else {
            if(selectedConnectionsIds?.length === 1){
                const newConnection = await applyRemoveTagsToConnection(selectedConnectionsIds[0], applyTags, removeTags)
                onSaveHandler && onSaveHandler(newConnection)
            } else {
                const result = await bulkApplyTags(applyTags, selectedConnectionsIds ?? [])
                onSaveHandler && onSaveHandler(result)
            }
        }
        setSubmitLoading(false)

    }, [tags, value])

    const onChangeTagsHandler = (value: any) => {
        setValue(value);
        onChangeHandler && onChangeHandler(value);
    }
    
    return <StyledForm onSubmit={onSubmitHandler}>
        <ApplyRemoveTagsSelect 
            prefilledValue={tags ?? []}
            onChangeHandler={(value: IEditableTag[]) => onChangeTagsHandler(value)}
        />
        {!inline && 
            <Row gap="10px">
                {onCancelHandler && <Button $type="cancel" $bigButton onClickHandler={() => onCancelHandler()}>Cancel</Button>}
                <Button $type='blue' type="submit" $bigButton={onCancelHandler ? true : false} isLoading={submitLoading}>{'Continue'}</Button>
            </Row>
            }
    </StyledForm>
}//listen on change and disable save button until the value is set. Then send the state with callback

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default ApplyRemoveTagForm
