import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Calendar from 'react-calendar'

import { Dropdown } from "react-bootstrap";

import Text from "../components/Text";
import SvgFragment from "./SvgFragment";
import { styles } from "../styles/themes/style";
import Select from "../components/select/Select";

interface IDueDateStyles {
    $selected?: boolean
}

interface SvgContainerProps {
    isOpen?: boolean
}

interface IDueDateProps {
    end?: number
    repeating?: {
        unit: number
    }
    onChangeHandler?: (e: any) => void
}


const DueDate: FunctionComponent<IDueDateProps> = ({ end, repeating, onChangeHandler }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [dueDate, setDueDate] = useState<Date | null>(end ? moment.unix(end).toDate() : null)
    const [repeat, setRepeat] = useState<any | null>(repeating && repeating?.unit && { value: repeating.unit, label: repeating.unit })

    const actionValuesObjects = [
        {
            value: 'Tomorrow',
            date: moment().add(1, 'day').toDate()
        },
        {
            value: 'In a week',
            date: moment().add(1, 'week').toDate()
        },
        {
            value: 'In a month',
            date: moment().add(1, 'month').toDate()
        },
    ]

    const repeatOptions = [
        { value: 'Never', label: 'Never', component: <Text $black>Never</Text> },
        { value: 'Daily', label: 'Daily', component: <Text $black>Daily</Text> },
        { value: 'Weekly', label: 'Weekly', component: <Text $black>Weekly</Text> },
        { value: 'Monthly', label: 'Monthly', component: <Text $black>Monthly</Text> }
    ]

    const dateChangedHandler = (props: string, event: any) => {
        if (props === 'dueDate') {
            setDueDate(moment(event[1]).set('hours', 0).set('minutes', 0).toDate())
            onChangeHandler && onChangeHandler({ end: moment(event[1]).set('hours', 0).set('minutes', 0).unix() })
        }
        if (props === 'actionText') {
            setDueDate(moment(event.date).set('hours', 0).set('minutes', 0).toDate())
            onChangeHandler && onChangeHandler({ end: moment(event.date).set('hours', 0).set('minutes', 0).unix() })
        }
    }

    const resetDueDateRepeat = () => {
        setDueDate(null)
        setRepeat({
            unit: 'Never',
            value: 0,
            active: false
        })
        onChangeHandler && onChangeHandler(undefined)
    }

    return (
        <Container>
            <StyledDropdown $selected={dueDate} onToggle={() => setIsDropdownOpen(!isDropdownOpen)} show={isDropdownOpen}>
                {dueDate ? <Text $black>{moment(dueDate).format('MMM D, YYYY')}</Text> : <Text $placeholder>No due date</Text>}
                <StyledDropdownToggle>
                    {dueDate ? (
                        <SvgContainer onClick={resetDueDateRepeat}> <SvgFragment type="dropdownClose" /> </SvgContainer>
                    ) : (
                        <SvgTransformContainer isOpen={isDropdownOpen}> <SvgFragment type="dropdownArrow" /> </SvgTransformContainer>
                    )}
                </StyledDropdownToggle>
                <StyledDropdownMenu>
                    <StyledDropdownItem onClick={(event: any) => event.stopPropagation()}>
                        <Actions>
                            {actionValuesObjects.map((v: any, index: number) => <ActionText key={index} onClick={() => dateChangedHandler('actionText', v)}>{v.value}</ActionText>)}
                        </Actions>
                        <Divider />
                        <StyledCalendar
                            value={dueDate}
                            onChange={event => dateChangedHandler('dueDate', event)}
                            showNeighboringMonth={false}
                            minDate={moment().toDate()}
                            returnValue='range'
                            minDetail='month'
                            formatMonthYear={(locale, date) => moment(date).format('MMMM')}
                            defaultActiveStartDate={dueDate ? dueDate : moment().toDate()}
                            locale='en-US'
                        />
                        <Divider />
                        <section>
                            <Text $label $lighter>Repeat</Text>
                            <Select
                                placeholder="Never"
                                defaultValue={repeat}
                                isSearchable={false}
                                options={repeatOptions}
                                onChange={(e: any) => {
                                    onChangeHandler && onChangeHandler({
                                        end: moment(dueDate).unix(), repeating: { unit: e.label, value: e.value, active: true }
                                    })
                                    setRepeat(e)
                                }}
                            />
                        </section>
                    </StyledDropdownItem>
                </StyledDropdownMenu>
            </StyledDropdown>
        </Container>
    )
}

const Container = styled.div``
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    width: 100%;
    height: 24px;
    display: flex;
    position: absolute;
    padding: 0;
    background-color: transparent;
    border: transparent;

    &:hover, &:active, &:focus-visible, &:checked {
        background-color: transparent !important;
    }

    .actions-dropdown-button::after {
        content: none;
    }

    .dropdown-toggle::after {
        display: none;
    }

    &.btn-check:checked + .btn, &:not(.btn-check) + .btn:active, &.btn:first-child:active, &.btn.active, &.btn.show {
        background-color: transparent !important;
    }

    svg {
        margin-left: auto;
    }
`
const StyledDropdown = styled(Dropdown) <IDueDateStyles>`
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    outline: none;
    border-radius: 4px;
    color: #333951;
    background-color: #FFFFFF;
    border: 2px solid ${({ $selected }) => $selected ? styles.colors.primary600 : '#DAE0E8'};
    cursor: pointer;

    &:hover {
        border: 2px solid ${styles.colors.primary600};
    }
`
const StyledDropdownMenu = styled(Dropdown.Menu)`
    width: 100%;
    height: 405px;
    margin: 5px 0 0 -10px;
    background-color: white;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    border: transparent;
    cursor: pointer;
`
const StyledDropdownItem = styled(Dropdown.Item)`
    display: flex;
    padding: 5px 10px;
    flex-direction: column;
    gap: 16px;

    &:hover, &:active, &:focus-visible, &:checked, &:focus {
        background-color: transparent !important;
    }

    &:hover {
        background-color: transparent;
        opacity: 100;
        & .LDtooltip { display: block; }
    }
`
const SvgContainer = styled.div`
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    margin-left: auto;
`
const SvgTransformContainer = styled(SvgContainer) <SvgContainerProps>`
    ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
`
/*  */
const Actions = styled.div`
    display: flex;
    width: 100%;
    height: 36px;
    align-items: center;
    justify-content: space-around;
`
const ActionText = styled.span`
    color: ${styles.colors.primary600};
    background-color: #EEF3FA;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid transparent;

    &:hover { border: 1px solid #5964FF !important; }
`
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #EBEFF4;
`
const StyledCalendar = styled(Calendar)`
    height: 220px;
    width: 240px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    color: ${styles.colors.black600};

    & abbr { text-decoration: none; }    

    & button {
        border: none;
        background-color: transparent;

        &:disabled, &:disabled:hover { color: ${styles.colors.disabledState}; }
        &:not(:disabled) {
            color: ${styles.colors.black600};
            cursor: pointer;
        }
    }

    & .react-calendar__navigation {
        display: flex;
        height: 20px;
        margin-bottom: 10px;
    }

    & .react-calendar__navigation__label {
        font-weight: bold;
        color: ${styles.colors.black600};
    }

    & .react-calendar__navigation__arrow {
        line-height: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #8c96ad;
    }

    & .react-calendar__viewContainer {
        height: 174px;
    }

    & .react-calendar__month-view__weekdays {
        height: 20px;
        margin-bottom: 4px;
    }

    & .react-calendar__month-view__weekdays__weekday {
        text-align: center;
    }

    & .react-calendar__month-view__days {
        height: 150px;
    }

    & .react-calendar__tile {
        height: 28px;
        width: 33px;
        margin-bottom: 2px;
        
        &:hover {color: ${styles.colors.primary600};}
    }

    & .react-calendar__tile--active {        
        background-color: ${styles.colors.primary100};
        color: ${styles.colors.black600} !important;

        &:hover { background-color: #F6FBFF; }
    }

    & .react-calendar__tile--rangeStart, & .react-calendar__tile--rangeEnd {
        border-radius: 6px;
        background-color: ${styles.colors.primary600};
        color: ${styles.colors.white} !important;
        
        &:hover {
            background-color: ${styles.colors.primary400};
            color: ${styles.colors.black600};
        }
    }
`
export default DueDate