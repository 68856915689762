import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Row from '../../../../components/Row'
import FilterButton from '../../../../fragments/FilterButton'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useTagsStore } from '../../../../stores/tags/useTagsStore'
import { useTagsHandlers } from '../../../../services/actions/tags/useTagsHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import DropdownItem from '../../../../components/dropdown/DropdownItem'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { styles } from '../../../../styles/themes/style'
import DropdownMenu from '../../../../components/dropdown/DropdownMenu'

interface ITagControlBarProps {
    selectedTags?: any
    setSelectedTags?: (e: any) => void
    isAllSelected?: boolean
    setIsAllSelected?: (e: boolean) => void
}

const TagControlBar: FunctionComponent<ITagControlBarProps> = ({ selectedTags, setSelectedTags, isAllSelected, setIsAllSelected }) => {
    const { store: { tagsFilter, tagsParameters } } = useTagsStore()
    const { store: { workspace} } = useAuthStore()
    const { setTagsParametersHandler, getTotalPaginatedTags, numberOfTagsFiltersHandler, setClearTagsFilterHandler, updateTagsAccessHandler } = useTagsHandlers()
    const { openSidebar } = useAppHandlers()

    useEffect(() => {
        getTags()
    }, [tagsFilter, tagsParameters?.page, tagsParameters?.pageSize])

    const getTags = useCallback(async () => {
        await getTotalPaginatedTags()
    }, [tagsFilter, tagsParameters?.page, tagsParameters?.pageSize])

    const resetFilterHandler = useCallback(() => {
        setClearTagsFilterHandler();
    }, [tagsFilter, setClearTagsFilterHandler]);

    const onClickHandler = async (isPrivate: boolean) => {
        await updateTagsAccessHandler( { selectedTags: selectedTags, private: isPrivate})
        setIsAllSelected && setIsAllSelected(false)
        setSelectedTags && setSelectedTags([])
    }

    return <Row gap='20px' alignItems spaceBetween flexWrap>
        <Row gap="12px">
            <Button $type='blue' $bigButton onClickHandler={() => openSidebar(SidebarTypes.TAG_EDIT_CREATE)}>{'Add new tag'}</Button>
            {workspace.isBusiness &&
                <StyledDropdownMenu disabled={(selectedTags.length === 0)} title={'Update Access'}>
                    <DropdownItem onClickHandler={() => onClickHandler(true)}>Private</DropdownItem>
                    <DropdownItem onClickHandler={() => onClickHandler(false)}>Public</DropdownItem>
                </StyledDropdownMenu>
            }
        </Row>
        <Row gap='12px' alignItems>
            <div style={{whiteSpace: 'nowrap'}}>
                <TablePageNavigation 
                    page={tagsParameters?.page ?? 1}
                    pageSize={tagsParameters?.pageSize ?? 25}
                    totalCount={tagsParameters?.total ?? 0}
                    onPageChanged={(value: number) => setTagsParametersHandler({ ...tagsParameters, page: value })}
                    onPageSizeChanged={(value: number) => setTagsParametersHandler({ ...tagsParameters, page: 1, pageSize: value })}
                />
            </div>
            <FilterButton 
                numberOfFilters={numberOfTagsFiltersHandler()} 
                sidebar={SidebarTypes.TAG_FILTER}
                resetFilter={() => resetFilterHandler()}/>
        </Row>
    </Row>
}

const StyledDropdownMenu = styled(DropdownMenu)`
    height: 34px;
    background-color: ${styles.colors.white};

    & .dropdown-toggle {
        padding: 7px;
    }
`

export default TagControlBar