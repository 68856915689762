import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TaskControlBar from '../components/tasks/TaskControlBar';
import TasksTable from '../components/tasks/TasksTable';

interface ITaskSectionProps { }

const TaskSection: FunctionComponent<ITaskSectionProps> = () => {

    return <Container>
        <Column>
            <TaskControlBar />
            <TasksTable />
        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

export default TaskSection
