import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from '../../../components/Row'
import StatBox from '../../../fragments/StatBox'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import { useAnalyticsHandlers } from '../../../services/actions/analytics/useAnalyticsHandlers'
import { IChartAnalytics } from '../../../interfaces/response/analytics'
import DashboardChartBox from '../components/dashboard/DashboardChartBox'
import StatisticsAreaBox from '../components/dashboard/StatisticsAreaBox'
import { useAppStore } from '../../../stores/application/useAppStore'
import { useAppActions } from '../../../stores/application/useAppActions'

interface IDashboardProps { }

interface IDashboardAnalytics {
    "connections-total": number
    'connections-untagged': number
    'connections-messaged': number
    'connections-with-notes': number
    'tags-total': number
}

const DashboardSection: FunctionComponent<IDashboardProps> = () => {
    const { store: { user } } = useAuthStore()
    const { store: { requestLoading } } = useAppStore()
    const navigate = useNavigate()
    const { openExtension } = useAppHandlers()
    const [ isFetching, setIsFetching ] = useState<boolean>(true)
    const [ analytics, setAnalytics ] = useState<IDashboardAnalytics>({
        'connections-total': 0,
        "connections-untagged": 0,
        'connections-messaged':  0,
        'connections-with-notes': 0,
        'tags-total': 0
    })
    const [ chartsAnalytics, setChartAnalytics ] = useState<IChartAnalytics>({
        countries: [],
        jobs: [],
        industries: []
    })
    const { getDashboardOverviewAnalytics, getDashboardChartAnalytics } = useAnalyticsHandlers()
    
    useEffect(() => {
        if('analyticsProfileStatistics' in requestLoading) { setIsFetching(requestLoading?.['analyticsProfileStatistics']) }
    }, [requestLoading])

    useEffect(() => {
        setIsFetching(true)
        getDashboardOverviewAnalyticsHandler()
        getDashboardChartAnalyticsHandler()
    }, [])

    const getDashboardOverviewAnalyticsHandler = useCallback( async () => {
        const statistics = await getDashboardOverviewAnalytics();
        let statisticsObject = statistics.reduce((accumulator, value) => ({...accumulator, ...{[value.version]: value.value}}), {});
        setAnalytics({...analytics, ...statisticsObject})
    }, [])
    const getDashboardChartAnalyticsHandler = useCallback( async () => {
        const statistics = await getDashboardChartAnalytics();
        console.log(isFetching)
        setChartAnalytics(statistics?.value as IChartAnalytics)
    }, [])

    //todo: some navigation needs to include filtering
    return <Container>
        <GeneralSection gap="24px">
            <StatBox svgType={'connections'} statistic={analytics['connections-total']} title={'Connections'} onClickHandler={() =>navigate(AppRoutes.CONNECTIONS)} />
            <StatBox svgType={'followers'} statistic={user?.linkedinData?.linkedinNetworkInfo?.followerCount  ||  0} title={`Followers (Coming soon)`} onClickHandler={() =>navigate(AppRoutes.CONNECTIONS)} />
            <StatBox svgType={'followings'} statistic={user?.linkedinData?.linkedinNetworkInfo?.followingCount || 0} title={`Followings (Coming soon)`} onClickHandler={() =>navigate(AppRoutes.CONNECTIONS)} />
        </GeneralSection>
        <GeneralSection gap="24px">
            <StatBox svgType={'tags'} statistic={analytics['tags-total']} title={`Number of tags`} onClickHandler={() =>navigate(AppRoutes.TAGS)} />
            <StatBox svgType={'tagged'} statistic={analytics['connections-untagged']  ||  0} title={`Untagged connections`} onClickHandler={() =>navigate(AppRoutes.CONNECTIONS, { state: { noTags: true } })} />
            <StatBox svgType={'messaged'} statistic={analytics['connections-messaged']  || 0} title={`Messaged connections (Coming soon)`} onClickHandler={() => openExtension()} />
            <StatBox svgType={'notes'} statistic={analytics['connections-with-notes']  || 0} title={`Connections with notes`} onClickHandler={() =>navigate(AppRoutes.CONNECTIONS, { state: { note: 'yes' } } )} />
        </GeneralSection>
        <GeneralSection gap="24px">
            <DashboardChartBox 
                type={'countries'} 
                title={'Most Popular Countries'} 
                data={!isFetching ? chartsAnalytics?.countries?.sort((x, y) => y.count - x.count) : []} 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.countries || []).reduce((result: number, item: any) => result += item.count, 0)} 
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { searchByLocation: { locationSearchValue: e, searchByLocation: 'yes' } } })}
            />
            <StatisticsAreaBox
                type={'jobs'} 
                title={'Most Popular Jobs'} 
                data={!isFetching ? chartsAnalytics?.jobs?.sort((x, y) => y.count - x.count) : []} 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.jobs || []).reduce((result, item) => result += item.count, 0)} 
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { searchByJobTitle: { jobTitleSearchValue: e, searchByJobTitle: 'yes' } } })} />
            <StatisticsAreaBox 
                type={'industries'} 
                title={'Most Popular Industries'} 
                data={!isFetching ? chartsAnalytics?.industries?.sort((x, y) => y.count - x.count) : []} 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.industries || []).reduce((result, item) => result += item.count, 0)} 
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { industry: e } })} />
        </GeneralSection>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const GeneralSection = styled(Row)`
    flex-wrap: wrap;
`

export default DashboardSection
