import { TTasksAction, TTasksState, ActionType, ITasksParametersState, ITasksFiltersState } from "../../interfaces/store/taskStore";
import { makeStore } from "../makeStore";

const initialTasksParameters: ITasksParametersState = {
    page: 1,
    pageSize: 25,
    sort: 'newest',
    total: undefined
}

const initialTasksFilters: ITasksFiltersState = {
    total: true,
    completed: false,
    endFrom: null,
    endTo: null,
    status: { value: 'outstanding', label: 'Outstanding' },
    type: { value: 'all', label: 'Show All' },
    repeating: { value: 'all', label: 'All' },
    selectedTasksIds: [],
    taskCondition: 'And',
    priority: []
}

const initialState: TTasksState = {
    tasks: [],
    tasksParameters: initialTasksParameters,
    tasksFilters: initialTasksFilters
}


const reducer = (state: TTasksState, action: TTasksAction): TTasksState => {
    switch (action.type) {
        case ActionType.SET_TASKS: return { ...state, tasks: action.payload }
        case ActionType.SET_TASKS_PARAMETERS: return { ...state, tasksParameters: { ...state.tasksParameters, ...action.payload } }
        case ActionType.SET_TASKS_FILTERS: return { ...state, tasksFilters: { ...state.tasksFilters, ...action.payload } }
        case ActionType.RESET_TASKS_PAGINATION: return {  ...state, tasksParameters: {...initialTasksParameters }, tasksFilters: {...initialTasksFilters} }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: TasksStoreProvider, useStoreContext: useTasksStore } = makeStore(reducer, initialState)

export { TasksStoreProvider, useTasksStore }