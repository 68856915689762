import { FunctionComponent, useEffect, useState } from "react";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import SvgFragment from "./SvgFragment";
import styled from "styled-components";
import DropdownItem from "../components/dropdown/DropdownItem";
import Checkbox from "../components/Checkbox";
import Text from "../components/Text";
import Row from "../components/Row";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";
import ActionText from "../components/ActionText";

interface IConnectionCustomizeColumnsProps { }


const ConnectionCustomizeColumns: FunctionComponent<IConnectionCustomizeColumnsProps> = () => {

    const { setConnectionsParametersHandler } = useConnectionsHandlers()
    const { store: { connectionsParameters } } = useConnectionsStore()

    const { columnsSetup } = connectionsParameters
    
    const [columns, setColumns] = useState<any>([
        { label: "Message", isSelected: true, value: "message" },
        { label: "Note", isSelected: true, value: "note" },
        { label: "Picture", isSelected: true, value: "profilePictureMapped" },
        { label: "Name", isSelected: true, value: "fullName" },
        { label: "Headline And Job Title", isSelected: true, value: "headlineAndJobTitle" },
        { label: "Company", isSelected: true, value: "company" },
        { label: "Location", isSelected: true, value: "location" },
        { label: "Date Connected", isSelected: false, value: "dateConnectedColumn" },
        { label: "Contact Info", isSelected: true, value: "emailColumn" },
        { label: "Last Messaged", isSelected: true, value: "lastMessagedColumn" },
        { label: "Tags", isSelected: true, value: "tagsColumn" },
        { label: "Languages", isSelected: false, value: "languagesColumn" },
        { label: "Date Of Birth", isSelected: false, value: "dateOfBirth" },
        { label: "Followers", isSelected: false, value: "followerCountColumn" },
        { label: "Shared Connections", isSelected: true, value: "mutualConnections" }
    ])

    const onClickHandler = (props: any) => {
        const { isSelected, value} = props

        const updated = columns.map((column: any) => {
            if (column.value === value) {
                return {
                    ...column,
                    isSelected: !isSelected
                }
            }
            return column
        })

        setColumns(updated)

        columnsSetup[value] = !isSelected

        localStorage.setItem('columns-setup', JSON.stringify(columnsSetup))

        setConnectionsParametersHandler({...connectionsParameters, columnsSetup: columnsSetup})
    }

    const resetColumns = () => {
        const defaultColumnsSetup = {
            "followerCountColumn": false,
            "message": true,
            "note": true,
            "profilePictureMapped": true,
            "fullName": true,
            "headlineAndJobTitle": true,
            "company": true,
            "location": true,
            "dateConnectedColumn": false,
            "emailColumn": true,
            "tagsColumn": true,
            "languagesColumns": false,
            "dateOfBirth": false,
            "mutualConnections": true
        }
        localStorage.setItem('columns-setup', JSON.stringify(defaultColumnsSetup))

        setConnectionsParametersHandler({...connectionsParameters, columnsSetup: defaultColumnsSetup})
    }


    useEffect(() => {
        const updated = columns.map((column: any) => {
            if (columnsSetup && columnsSetup?.hasOwnProperty(column.value)) {
                return {
                    ...column,
                    isSelected: columnsSetup[column.value]
                }
            }
            return column
        })
        setColumns(updated)
    }, [columnsSetup])

    return (
        <DropdownMenu autoClose='outside' $hideToggle title={<SvgFragmentWrapper><SvgFragment type='columnsTable' /></SvgFragmentWrapper>}>
            <StyledRow alignItems gap='5px'>
                    <Text $bold>View Columns </Text>
                    (<ActionText onClickHandler={resetColumns}>Reset To Default</ActionText>)
            </StyledRow>
            {columns.map((column: any) => {
                return (
                    <DropdownItem key={column?.value} onClickHandler={() => onClickHandler(column)}>
                        <Row gap="10px" alignItems>
                            <StyledCheckbox checkboxId={column.value} checked={column.isSelected} onClickHandler={() => onClickHandler(column)} />
                            <StyledText >{column.label}</StyledText>
                        </Row>
                    </DropdownItem>
                )
            })}
        </DropdownMenu>
    )
}

const SvgFragmentWrapper = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`
const StyledText = styled(Text)`
    font-weight: normal;
`
const StyledRow = styled(Row)`
    padding: 0 20px;
    text-wrap: nowrap;
`
export default ConnectionCustomizeColumns