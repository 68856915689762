import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Column from "../../components/Column";
import { INote } from "../../interfaces/models/note";
import PrivateCheckBoxControl from "../PrivateCheckBoxControl";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import { styles } from "../../styles/themes/style";
import ContentEditable from 'react-contenteditable'
import { sanitize } from "../../utils";
import { ICreateNote, useNotesHandlers } from "../../services/actions/notes/useNotesHandlers";
import Row from "../../components/Row";
import ProfilePhoto from "../../components/ProfilePhoto";
import { useAuthStore } from "../../stores/auth/useAuthStore"
import { useAppStore } from "../../stores/application/useAppStore";
import moment from "moment";
import EditDeleteActionDropdown from "../dropdowns/EditDeleteActionDropdown";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import ActionText from "../../components/ActionText";

interface INoteSidebarProps {
    note?: INote
    connectionId?: string
    existingNotes?: any
    editNote?: boolean
    showEmptyPage?: boolean
    onCloseHandler?: () => void
    onCreateHandler?: (noteId: string | null, note: ICreateNote, updateNotes?: (notes: any) => void) => void
}
interface ICustomTextAreaStyles {
    $isSelected?: boolean
}
const CreateEditNoteForm: FunctionComponent<INoteSidebarProps> = ({note, connectionId, existingNotes, editNote, showEmptyPage = false, onCloseHandler, onCreateHandler}) => {
    const contentEditableRef = useRef<HTMLDivElement>(null)
    const textRef = useRef('')
    const { store: { workspace} } = useAuthStore()

    const { workspaceUsersPublicAllHandler, deleteNoteHandler, createUpdateNoteHandler } = useNotesHandlers()
    const { setDestructiveModal, hideModal } = useAppHandlers()

    const removeHtmlTags = (str: any) => { return str?.length && str.replace(/<\/?[^>]+(>|$)/g, "") }

    const [noteState, setNoteState] = useState<any>(note ? {_id: note?._id, text: note?.text, private: note?.private} : {_id: undefined, text: ' ', private: true})
    const [showInputDropdown, setShowInputDropdown] = useState<boolean>(false)
    const [dropdownPosition, setDropdownPosition] = useState<any>({ top: 0, left: 0 })
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const { store: { requestLoading } } = useAppStore()

    const [addNoteState, setAddNoteState] = React.useState(editNote || !showEmptyPage);
    const [editingNote, setEditingNote] = useState<any>();
    const [existingNotesState, setExistingNotesState] = useState<any>(existingNotes);
    const [addingNote, setAddingNote] = React.useState(false);
    const [disabledCheckbox, setDisabledCheckbox] = React.useState(false);
    
    /* TODO: This can be done better */
    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])
    const [filteredWorkspaceUsers, setFilteredWorkspaceUsers] = useState<any>([])

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '' 
        })
        setWorkspaceUsers(result)
    }

    useEffect(() => {
        if(editingNote?.text?.length) {
            if(typeof editingNote.text === 'string') {
                textRef.current = editingNote.text
            } else {
                textRef.current = editingNote.text[0]
            }
        }
    }, [editingNote])

    useEffect(() => {
        if(note?.text?.length) {
            if(typeof note.text === 'string') {
                textRef.current = note.text
            } else {
                textRef.current = note.text[0]
            }
        }
    }, [note])

    useEffect(() => {
        workspaceUsersHandler()
    }, [])

    useEffect(() => {
        setExistingNotesState(existingNotes);
    }, [existingNotes])
    
    useEffect(() => {
        setFilteredWorkspaceUsers(workspaceUsers)
    }, [workspaceUsers])

    useEffect(() => {
        if('createNote' in requestLoading){
            setSubmitLoading(requestLoading?.['createNote'])
        }
        if(!requestLoading?.['createNote']){
            if('updateNote' in requestLoading){
                setSubmitLoading(requestLoading?.['updateNote'])
            }
        }
    }, [requestLoading]);

    const getCaretCoordinates = (contentEditableDiv: HTMLDivElement) => {
        const span = document.createElement('span')
        span.style.position = 'absolute'
        span.style.visibility = 'hidden'

        contentEditableDiv.appendChild(span)

        const rect = span.getBoundingClientRect()
        const top = rect.top + window.scrollY
        const left = rect.left + window.scrollX
        
        contentEditableDiv.removeChild(span)
        
        return {
            top: top - contentEditableDiv.getBoundingClientRect().top + contentEditableDiv.scrollTop,
            left: left - contentEditableDiv.getBoundingClientRect().left + contentEditableDiv.scrollLeft
        }
    }

    const setTaggedConnectionHandler = (workspaceUser: any) => {
        const textCurrent = textRef.current
        const id = workspaceUser._id
        const name = workspaceUser?.firstName + ' ' + workspaceUser.lastName

        const tagSpan = `&nbsp;<span data-id="${id}" data-object="user" data-type="tag" data-label="@${name}" class="ld-tag">@${name}</span>&nbsp;`

        if(textCurrent.endsWith('@')) textRef.current = textCurrent.slice(0, -1) + tagSpan
        else textRef.current = textCurrent.replace(/@[\w]+(?!.*@[\w]+)/, '') + tagSpan

        setFilteredWorkspaceUsers(workspaceUsers)
        setNoteState({...note, private: false})
        setShowInputDropdown(false)
        setDisabledCheckbox(true);
    }

    const onDeleteNoteHandler = async (noteId: string) => {
        setDestructiveModal({
            headingText: 'Delete note',
            descriptionText: "Are you sure you want to delete this note?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                deleteNoteConfirmed(noteId)
            }
        })
    }

    const deleteNoteConfirmed = async (noteId: string) => {
        const result = await deleteNoteHandler(noteId)
        if(result?.success){
            setExistingNotesState(result.data?.notes)
        }
    }

    const handleChange = (e: any) => {
        const div = contentEditableRef.current

        const inputString = e?.target?.value || ''
        const stringWithoutHtml = e?.target?.value.replace(/<\/?[^>]+(>|$)/g, "")
        const stringWithSpaces = stringWithoutHtml.replace(/&nbsp;/g, " ")
        const words = stringWithSpaces.trim().split(/\s+/);
        const lastWord = words[words.length - 1]
        const lastWordStartsWithAt = lastWord.startsWith('@')

        if(div) {
            if(e.nativeEvent.inputType === 'deleteContentBackward' && (removeHtmlTags(inputString).length === 0 || removeHtmlTags(inputString).length === undefined)) {
                textRef.current = '&nbsp;'

                setShowInputDropdown(false)
            } else if(e.nativeEvent.data === '@') {
                textRef.current = e.target.value 

                const { top, left } = getCaretCoordinates(div)
                setDropdownPosition({ top, left })

                setShowInputDropdown(true)
            } else if(lastWordStartsWithAt) {
                setShowInputDropdown(true)

                textRef.current = e.target.value

                const lowerCaseQuery = lastWord.replace(/^@/, '').toLowerCase()
                const filtered = workspaceUsers.filter((workspaceUser: any) => workspaceUser.firstName.toLowerCase().startsWith(lowerCaseQuery) || workspaceUser.lastName.toLowerCase().startsWith(lowerCaseQuery))

                if(filtered.length === 0) setShowInputDropdown(false)

                setFilteredWorkspaceUsers(filtered)
            } else {
                setShowInputDropdown(false)

                textRef.current = e.target.value
            }
        }

        if(containTags(inputString)){
            setDisabledCheckbox(true);
        } else {
            setDisabledCheckbox(false);
        }
    }

    const createUpdateNote = async () => {
        setAddingNote(true); 
        setSubmitLoading(true);

        let body = note ?  
        {
            private: noteState.private,
            text: [textRef.current]
        } : {
            connection: connectionId,
            private: noteState?.private,
            text: [textRef.current]
        }

        if(onCreateHandler){
            await onCreateHandler(noteState?._id, body, (updatedNotes) => {
                if(updatedNotes){
                    setExistingNotesState(updatedNotes);
                }
            });
        }
        else {
            const result = await createUpdateNoteHandler(noteState?._id, body);
            if(result){
                setExistingNotesState(result.notes);
            }
        }

        if(editingNote){
            let updatedNotes = existingNotesState.map((note: any) => {
                if (note._id === editingNote._id) {
                    return {...note, text: [textRef.current], private: noteState?.private};
                } else {
                    return note;
                }
            })
            setExistingNotesState(updatedNotes);
        }
        setEditingNote(undefined);
        setAddNoteState(!showEmptyPage ?? false);
        setAddingNote(false); 
        setSubmitLoading(false);
        setNoteState({_id: undefined, text: ' ', private: true});
        textRef.current = '';
        setDisabledCheckbox(false);
    }

    const onEditNoteHandler = async (note: any) => {
        setAddNoteState(false);
        setNoteState(note);
        setEditingNote(note);
        if(note?.text?.length) {
            if(typeof note.text === 'string') {
                textRef.current = note.text
            } else {
                textRef.current = note.text[0]
            }
            if(containTags(note.text)){
                setDisabledCheckbox(true);
            }
        } 
    }

    const onCloseEditingNoteHandler = async () => { 
        setEditingNote(undefined);
        onCloseHandler && onCloseHandler();
    }

    const onCloseAddingNoteHandler = async () => {
        setAddNoteState(false); 
        setEditingNote(undefined);
        onCloseHandler && onCloseHandler();
    }

    const containTags = (input: any) => {
        const noteText = (Array.isArray(input) ? input.join('') : input);
        return noteText?.includes('data-type="tag"');
    }

    return (
        <StyledColumn gap="10px">
                     <Row spaceBetween>
                     <Text $heading6>{'Notes'}</Text>
                     {existingNotesState?.length > 0 && <ActionText 
                        onClickHandler={() => {setAddNoteState(true); setEditingNote(undefined); setNoteState({_id: undefined, text: ' ', private: true}); textRef.current = '';}}
                            >{'Add note'}
                        </ActionText>
                     }
                    </Row>
            {addNoteState && (!editingNote || editNote) &&
                <>
                    <StyledTextarea
                    innerRef={contentEditableRef}
                    html={sanitize(textRef.current)}
                    disabled={false}
                    onChange={handleChange}
                    />
                    <StyledDropdown show={showInputDropdown} top={dropdownPosition.top} left={dropdownPosition.left}>
                        {filteredWorkspaceUsers.map((workspaceUser: any, index: number) => (
                            <DropdownItem key={index} onClick={() => setTaggedConnectionHandler(workspaceUser)}>
                                <Row alignItems gap="10px">
                                    <ProfilePhoto capitalLetters={`${workspaceUser.firstName.charAt(0).toUpperCase()}${workspaceUser.lastName.charAt(0).toUpperCase()}`} />
                                    <Column>
                                        <Text>{workspaceUser.firstName + ' ' + workspaceUser.lastName}</Text>
                                        <Text>{workspaceUser.email}</Text>
                                    </Column>
                                </Row>
                            </DropdownItem>
                        ))}
                    </StyledDropdown>
                    <Text $paragraph3>Tagging someone will automatically make this note public</Text>
                    { 
                    workspace.isBusiness && <PrivateCheckBoxControl
                            type='note'
                            value={noteState?.private}
                            disabled={disabledCheckbox}
                            onChangeHandler={(value: boolean) => setNoteState((previousState: any) => ({ ...previousState, private: value }))}
                        /> 
                        }
                        <ButtonWrapper>
                            <Button $type="cancel" onClickHandler={() => onCloseAddingNoteHandler()}>Cancel</Button>
                            <Button $type="blue" onClickHandler={createUpdateNote} isLoading={submitLoading}>Save</Button>
                        </ButtonWrapper>
                    </>
                    }
                    {existingNotesState?.length > 0 ? (existingNotesState?.map((note: any) => {
                            return (
                                editingNote && editingNote._id === note._id ? (
                                   
                                    <>
                                        <StyledTextarea
                                        innerRef={contentEditableRef}
                                        html={sanitize(textRef.current)}
                                        disabled={false}
                                        onChange={handleChange}
                                        />
                                            <StyledDropdown show={showInputDropdown} top={dropdownPosition.top} left={dropdownPosition.left}>
                                                {filteredWorkspaceUsers.map((workspaceUser: any, index: number) => (
                                                    <DropdownItem key={index} onClick={() => setTaggedConnectionHandler(workspaceUser)}>
                                                        <Row alignItems gap="10px">
                                                            <ProfilePhoto capitalLetters={`${workspaceUser.firstName.charAt(0).toUpperCase()}${workspaceUser.lastName.charAt(0).toUpperCase()}`} />
                                                            <Column>
                                                                <Text>{workspaceUser.firstName + ' ' + workspaceUser.lastName}</Text>
                                                                <Text>{workspaceUser.email}</Text>
                                                            </Column>
                                                        </Row>
                                                    </DropdownItem>
                                                ))}
                                            </StyledDropdown>
                                            <Text $paragraph3>Tagging someone will automatically make this note public</Text>
                                        { 
                                        workspace.isBusiness && <PrivateCheckBoxControl
                                                type='note'
                                                value={noteState?.private}
                                                disabled={disabledCheckbox}
                                                onChangeHandler={(value: boolean) => setNoteState((previousState: any) => ({ ...previousState, private: value }))}
                                            /> 
                                            }
                                            <ButtonWrapper>
                                                <Button $type="cancel" onClickHandler={() => onCloseEditingNoteHandler()}>Cancel</Button>
                                                <Button $type="blue" onClickHandler={createUpdateNote} isLoading={submitLoading}>Save</Button>
                                            </ButtonWrapper>
                                    </>
                                ) : (
                                    <StyledNoteRow spaceBetween key={note._id}>
                                        <Column gap='10px'>
                                            <Text $bold $black>{<InnerHtml dangerouslySetInnerHTML={{ __html: sanitize(note.text) }} />}</Text>
                                            <Text $label $lighter>{moment(note.createdAt).format('MMM D, YYYY HH:mm')}</Text>
                                        </Column>
                                        <EditDeleteActionDropdown
                                            object={note}
                                            type='note'
                                            onDeleteHandler={() => onDeleteNoteHandler(note._id)}
                                            onEditHandler={() => onEditNoteHandler(note)}
                                        />
                                    </StyledNoteRow>
                                 )
                            );
                        }
                    )
                ) : (!editingNote && !addNoteState && !addingNote && !editNote && showEmptyPage && (
                    <EmptyListWrapper>
                        <EmptyListLabel>No notes to show</EmptyListLabel>
                        <EmptyListButton onClick={() => setAddNoteState(true)}>Add note</EmptyListButton>
                    </EmptyListWrapper>
                    )   
                )
            }
        </StyledColumn>
    )
}
const StyledColumn = styled(Column)`
    position: relative;
`
const StyledTextarea = styled(ContentEditable)<ICustomTextAreaStyles>`
    width: 100%;
    height: 100px;
    border: 2px solid ${({ $isSelected }) => $isSelected ? styles.colors.primary600 : styles.colors.black200};
    border-radius: 4px;
    padding: 10px;
    resize: none;

    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #333951;

    & .ld-tag {
        background-color: #faf4ba;
        color: #5964ff;
    }
    
    & p {
        margin: 0;
    }
`
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`
const StyledDropdown = styled(DropdownMenu)<{ top: number; left: number }>`
    position: absolute;
    top: ${(props) => props.top + 18}px;
    left: ${(props) => props.left}px;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;

    & svg { display: none }
`
const DropdownItem = styled.div`
    padding: 10px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`
const StyledNoteRow = styled(Row)`
    width: 100%;
    padding: 10px;
    background: ${styles.colors.black100};
`
const InnerHtml = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`
const EmptyListWrapper = styled.div`
    height: 86px;
    padding 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: ${styles.colors.black100};
`
const EmptyListLabel = styled.p`
    font-weight: 700;
    line-height: 14px;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${styles.colors.black300};
    font-family: NunitoSans;
`
const EmptyListButton = styled.p`
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    color: ${({theme: {secondaryColor}}) => secondaryColor};
    background-color: ${styles.colors.primary600};
`
export default CreateEditNoteForm