import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DateComponent from "../../components/DateComponent";
import { useNotesStore } from "../../stores/notes/useNotesStore";
import { useNotesActions } from "../../stores/notes/useNotesActions";
import Input from "../../components/Input";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import AdvancedFilterFragment from "../AdvancedFilterFragment";

import { useTasksHandlers } from "../../services/actions/tasks/useTasksHandlers";
import FormControl from "../../components/FormControl";
import Select from "../../components/select/Select";
import { useTasksStore } from "../../stores/tasks/useTasksStore";
import Text from "../../components/Text";
import Tag from "../../components/Tag";
import { styles } from "../../styles/themes/style";
import Row from "../../components/Row";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";

interface IFilterTasksFormProps { }

interface PriorityOption {
    value: number;
    label: string;
    component?: JSX.Element;
}

const typeOptions = [
    { value: 'No Type', label: 'No Type' },
    { value: 'General', label: 'General' },
    { value: 'Deadline', label: 'Deadline' },
    { value: 'In-person meeting', label: 'In-person meeting' },
    { value: 'Online meeting', label: 'Online meeting' },
    { value: 'Email', label: 'Email' },
    { value: 'Call', label: 'Call' },
    { value: 'Book a meeting', label: 'Book a meeting' },
    { value: 'LinkedIn message', label: 'LinkedIn message' },
    { value: 'LinkedIn connection add', label: 'LinkedIn connection add' }
]

const repeatingOptions = [
    { value: 'all', label: 'All' },
    { value: 'Never', label: 'Never' },
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' }
]

const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'completed', label: 'Completed' },
    { value: 'outstanding', label: 'Outstanding' },
    { value: 'overdue', label: 'Overdue' }
]

const priorityOptions: PriorityOption[] = [
    { value: -1, label: 'All Priority Levels', component: <Text $lighter>All Priority Levels</Text> },
    { value: 0, label: 'No Priority Level', component: <Text $lighter>No Priority Level</Text> },
    { value: 1, label: 'Low', component: <Tag title="Low" $color={styles.colors.blue600} /> },
    { value: 2, label: 'Medium', component: <Tag title="Medium" $color={styles.colors.green500} /> },
    { value: 3, label: 'High', component: <Tag title="High" $color={styles.colors.orange600} /> },
    { value: 4, label: 'Urgent', component: <Tag title="Urgent" $color={styles.colors.red600} /> }
]

const FilterTasksForm: FunctionComponent<IFilterTasksFormProps> = () => {

    const { store: { tasksFilters } } = useTasksStore()

    const { taskCondition, priority } = tasksFilters

    const { setTasksFiltersHandler, setClearTasksFilterHandler } = useTasksHandlers()
    const { closeSidebar } = useAppHandlers()

    const onSelectedItemHandler = (items: PriorityOption[], item: any) => {
        let values: { value: number; label: string; }[] = []

        const hasAllValues = items.filter((option: any) => [1, 2, 3, 4].includes(option.value)).length === 4
        const hasNoValues = priority.some((item: any) => item.value === 0)

        const selectAllValues = item.value === -1
        const selectNoValues = item.value === 0

        if(selectAllValues) {
            if(hasAllValues) {
                values = []
            } else {
                values = priorityOptions.filter(option => [-1, 1, 2, 3, 4].includes(option.value)).map(({ component, ...rest }) => rest)
            }
        } else if(selectNoValues) {
            if(hasNoValues) {
                values = items.filter(option => option.value !== 0).map(({ component, ...rest }) => rest)
            } else {
                values = priorityOptions.filter(option => option.value === 0).map(({ component, ...rest }) => rest)
            }
        } else {
            values = items.filter((option: any) => ![-1, 0].includes(option.value))
        }

        setTasksFiltersHandler({...tasksFilters, priority: values})
    }

    const onSelectedTabHandler = (e: any) => {
        setTasksFiltersHandler({...tasksFilters, taskCondition: e})
    }

    const onResetSelectionsHandler = () => {
        setTasksFiltersHandler({...tasksFilters, priority: [], taskCondition: 'And'})
    }

    const resetFilterHandler = (e: any) => {
        setTasksFiltersHandler({...tasksFilters, type: e})
    }

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.TASKS_FILTER)
    }, [tasksFilters]);

    const resetFormHandler = useCallback(() => {
        setClearTasksFilterHandler();
    }, [tasksFilters, setClearTasksFilterHandler]);

    return (
        <StyledForm>
            <DateComponent
                title='Due date'
                dateFrom={tasksFilters.endFrom ? tasksFilters.endFrom : ''}
                dateTo={tasksFilters.endTo ? tasksFilters.endTo : ''}
                dateFromValueChangedHandler={(e: any) => setTasksFiltersHandler({...tasksFilters, endFrom: e})}
                dateToValueChangedHandler={(e: any) =>  setTasksFiltersHandler({...tasksFilters, endTo: e})}
                resetFilter={() => setTasksFiltersHandler({...tasksFilters, endFrom: '', endTo: ''})}
            />
            <FormControl $orientation="column">
                <Select
                    placeholder="No type"
                    isSearchable={false}
                    options={typeOptions}
                    value={tasksFilters?.type ? {label: 'Type: ' + tasksFilters?.type.label} : undefined}
                    onChange={(e: any) => resetFilterHandler(e)}
                />
            </FormControl>
            <FormControl $orientation="column">
                <AdvancedFilterFragment
                    showSearchField={false}
                    filterOptions={priorityOptions.map((item: any) => ({
                        value: item.value,
                        label: item.component,
                        id: item.value,
                        selected: false,
                        object: item
                    }))}
                    selectedItems={priority.map((item: any) => ({ _id: item.value }))}
                    infoTitle={"Choose whether you want to get items that all selected users share (AND) or any of the selected users (OR)."}
                    inputTitle={"Priority level"}
                    tabs={[{title: 'And', slug: 'And', selected: taskCondition !== 'Or'}, {title: 'Or', slug: 'Or', selected: taskCondition === 'Or'}]} 
                    onSelectedItemHandler={onSelectedItemHandler}
                    onSelectedTabHandler={onSelectedTabHandler}
                    onResetSelectionsHandler={onResetSelectionsHandler}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Select
                    placeholder="Status"
                    isSearchable={false}
                    options={statusOptions}
                    value={tasksFilters?.status ? {label: 'Status: ' + tasksFilters?.status.label} : undefined}
                    onChange={(e: any) => setTasksFiltersHandler({...tasksFilters, status: e})}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Select
                    placeholder="Repeating tasks"
                    isSearchable={false}
                    options={repeatingOptions}
                    value={tasksFilters?.status ? {label: 'Repeating tasks: ' + tasksFilters?.repeating.label} : undefined}
                    onChange={(e: any) => setTasksFiltersHandler({...tasksFilters, repeating: e})}
                />
            </FormControl>
            <Row gap="10px" spaceBetween>
                <ActionText danger onClickHandler={() => resetFormHandler()}>Clear filters</ActionText>
                <Row gap="10px" alignItems>
                    <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                </Row>
            </Row>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`
export default FilterTasksForm