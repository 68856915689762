import { FunctionComponent } from 'react'
import Row from '../../../../components/Row'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useTemplateStore } from '../../../../stores/templates/useTemplateStore'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Input from '../../../../components/Input'
import styled from 'styled-components'
import { useTemplateHandlers } from '../../../../services/actions/template/useTemplateHandlers'
import debounce from 'lodash.debounce'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../../../services/actions/auth/useAuthHandlers'

interface ITemplatesControlBarProps { }

const TemplatesControlBar: FunctionComponent<ITemplatesControlBarProps> = () => {
    const { openSidebar } = useAppHandlers()
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { setTemplatesFiltersHandler, setTemplatesParametersHandler, getTemplatesHandler } = useTemplateHandlers()

    const { store: { templatesParameters, templatesFilters } } = useTemplateStore()
    const { store: { user } } = useAuthStore()

    const { page, pageSize, total } = templatesParameters

    const searchByNameHandler = debounce((value: string) => { 
        setTemplatesFiltersHandler({...templatesFilters, searchByName: value})
    }, 500)

    const isDefaultTemplates = user?.preferences?.hideDefaultTemplates

    const onHideDefaultTemplatesHandler = async () => {
        await saveUserPreferencesHandler({hideDefaultTemplates: !isDefaultTemplates})
        getTemplatesHandler()
    }

    return <Row gap='20px' alignItems spaceBetween flexWrap>
        <Row gap="12px">
            <Button $type='blue' $bigButton onClickHandler={() => openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template' })}>{'Add new template'}</Button>
            <Button $type='white' $extraBigButton onClickHandler={onHideDefaultTemplatesHandler}>{`${isDefaultTemplates ? 'Show' : 'Hide'} default templates`}</Button>
        </Row>
        <Row gap='12px'>
            <TablePageNavigation
                page={page ?? 1}
                pageSize={pageSize ?? 25}
                totalCount={total}
                onPageChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, page: value})}
                onPageSizeChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, pageSize: value})}
            />
            <div style={{ width: 'auto' }}><Input placeholder="Search template name ..." onChangeHandler={(e: any) => searchByNameHandler(e)} /></div>
        </Row>
    </Row>
}

export default TemplatesControlBar