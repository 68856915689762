import { FunctionComponent, useState } from "react";
import AdvancedSearchFragment from "./AdvancedSearchFragment";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";
import debounce from "lodash.debounce";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";

interface ISearchFilterProps { }

const ConnectionSearchFilter: FunctionComponent<ISearchFilterProps> = () => {

    const { setConnectionsFilterHandler } = useConnectionsHandlers()

    const { store: { connectionsFilter } } = useConnectionsStore()

    const [inputValue, setInputValue] = useState('');

    const { isValueSearchOr } = connectionsFilter

    const [searchByOption, setSearchByOption] = useState<any>(['searchByName', 'searchByHeadline', 'searchByJobTitle', 'searchByCompany', 'searchByLocation'])

    const updateSearchParams = (updates: any) => {
        const newParams = { ...connectionsFilter, ...updates };
        setConnectionsFilterHandler(newParams);
    }

    const searchHandler = debounce(async (type: string, value: string) => {
        let newArray

        if(type === 'searchByValue') {
            updateSearchParams({searchValue: value})
        } else if(type === 'selectedTab') {
            updateSearchParams({isValueSearchOr: value === 'Or' ? 'yes' : 'no'})
        } else {
            if (value === 'all') {
                updateSearchParams({
                    searchByName: 'yes',
                    searchByHeadline: 'yes',
                    searchByJobTitle: 'yes',
                    searchByCompany: 'yes',
                    searchByLocation: 'yes',
                    searchByNote: 'yes'
                })
                return setSearchByOption(['all'])
            } else {
                newArray = searchByOption.filter((item: any) => item !== 'all')
                const isAll = searchByOption.some((item: any) => item === 'all')

                if (searchByOption.includes(value)) {
                    const updateArray = newArray.filter((item: any) => item !== value)

                    updateSearchParams({[value]: 'no'})
                    return setSearchByOption(updateArray)
                } else {
                    let params

                    if(isAll) {
                        params = {
                            searchByName: 'no',
                            searchByHeadline: 'no',
                            searchByJobTitle: 'no',
                            searchByCompany: 'no',
                            searchByLocation: 'no',
                            searchByNote: 'no',
                            [value]: 'yes' }
                    } else {
                        params = { [value]: 'yes' }
                    }
                    newArray = [...newArray, value]
                    
                    updateSearchParams(params)
                    return setSearchByOption(newArray)
                }
            }
        }
    }, 500);

    const resetSelectedValuesHandler = () => {
        setSearchByOption(['searchByName', 'searchByHeadline', 'searchByJobTitle', 'searchByCompany', 'searchByLocation'])

        updateSearchParams({
            searchByName: 'yes',
            searchByHeadline: 'yes',
            searchByJobTitle: 'yes',
            searchByCompany: 'yes',
            searchByLocation: 'yes',
            searchByNote: 'no',
            isValueSearchOr: 'yes',
            searchValue: '',
        })
        setInputValue('')
    }

    const listItems = [
        {label: 'Name', value: 'searchByName' },
        {label: 'Headline', value: 'searchByHeadline' },
        {label: 'Job title', value: 'searchByJobTitle' },
        {label: 'Company', value: 'searchByCompany' },
        {label: 'Location', value: 'searchByLocation' }
    ]

    const listItemsWitNote = [
        ...listItems,
        {label: 'Note', value: 'searchByNote' }
    ]

    return (
        <AdvancedSearchFragment
            tabs={[{title: 'Or', slug: 'Or', selected: isValueSearchOr === 'yes'}, {title: 'And', slug: 'And', selected: isValueSearchOr !== 'yes'}]}
            infoTitle={""}
            itemsLabel={'Search by'}
            inputValue={inputValue || connectionsFilter?.searchValue}
            isCheckboxChecked={(value: any) => searchByOption.includes(value)}
            setSelectedTab={(e) => searchHandler('selectedTab', e)}
            searchByOption={searchByOption}
            searchByOptionHandler={(e) => searchHandler('searchByOption', e)}
            searchByValueHandler={(e) => {setInputValue(e); searchHandler('searchByValue', e)}}
            resetSelectedValues={resetSelectedValuesHandler}
            children={isValueSearchOr === 'yes' ? listItems : listItemsWitNote}
        />
    )

}

export default ConnectionSearchFilter