import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { ILinkedInUserData } from "../../../interfaces/models/user"

export interface IOnboardingData {
    usingLeadDeltaFor: string,
    teamSize: string,
    hearAboutUs: string
}

export interface ISaveLinkedProfileData {
    userLinkedInInfo: ILinkedInUserData
}

export const useUserRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/user'

    //GET
    const getWorkspaces = () => {
        return sendRequest({
            slug: 'getUserWorkspaces',
            url: `${route}/workspaces`,
            method: 'GET'
        })
    }

    //POST
    const postOnboardingData = (payload: IOnboardingData) => {
        return sendRequest({
            slug: 'onboardingData',
            url: `${route}/on-boarding-data`,
            method: 'POST',
            payload,
            displayToast: false
        })
    };

    const saveLinkedInData = (payload: ISaveLinkedProfileData) => {
        return sendRequest({
            slug: 'linkedinProfileData',
            url: `${route}/linkedin-profile-data`,
            method: 'POST',
            payload,
            displayToast: false
        })
    }

    const saveUserPreferences = (payload: any) => {
        return sendRequest({
            slug: 'saveUserPreferences',
            url: `${route}/preferences`,
            method: 'POST',
            payload
        })
    }

    const saveTagsAndNotesSettings = (payload: any) => {
        return sendRequest({
            slug: 'saveTagsAndNotesSettings',
            url: `${route}/tags-and-notes-settings`,
            method: 'POST',
            payload,
            all: true
        })
    }

    const getUserOnboardingTasksState = () => {
        return sendRequest({
            slug: 'getOnboardingTasksState',
            url: `${route}/onboarding-steps`,
            method: 'GET'
        })
    }

    return {
        getWorkspaces,
        postOnboardingData,
        saveLinkedInData,
        saveUserPreferences,
        getUserOnboardingTasksState,
        saveTagsAndNotesSettings
    };
}

