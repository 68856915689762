import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTasksStore } from '../../../../stores/tasks/useTasksStore'
import { useTasksHandlers } from '../../../../services/actions/tasks/useTasksHandlers'
import Checkbox from '../../../../components/Checkbox'
import Table from '../../../../components/Table'
import Text from '../../../../components/Text'
import moment from 'moment'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'
import Column from '../../../../components/Column'
import SvgFragment from '../../../../fragments/SvgFragment'
import Tag from '../../../../components/Tag'
import ProfilePhoto from '../../../../components/ProfilePhoto'
import { calculateColumnWidth, capitalLetters } from '../../../../utils'
import EditDeleteActionDropdown from '../../../../fragments/dropdowns/EditDeleteActionDropdown'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import Row from '../../../../components/Row'
import { useRemindersHandlers } from '../../../../services/actions/reminders/useRemindersHandlers'
import SelectAllCheckbox from '../../../../components/SelectAllCheckbox'
import { useAppStore } from '../../../../stores/application/useAppStore'
import EmptyPage from '../../../../components/EmptyPage'
import LinkedConnectionColumn from './TasksTableComponents/LinkedConnectionColumn'
import useMediaQuery from '../../../../components/useMediaQuery'

interface ITasksTableProps { }

interface IStyledText {
    $red?: boolean
    $associations?: boolean
}

interface ICellContent {
    $bold?: boolean
    $blueText?: boolean
    $greyText?: boolean
    $redText?: boolean
    $cursorPointer?: boolean
    $inlineBlock?: boolean
}

interface ICheckMark {
    $completed?: boolean
}

interface ITableColumn {
    header: string;
    key: string[];
    width?: string;
}

export const renderTags = (props: number) => {
    switch (props) {
        case 0:
            return '';
        case 1:
            return <Tag title="Low" $color={styles.colors.blue600} />;
        case 2:
            return <Tag title="Medium" $color={styles.colors.green500} />;
        case 3:
            return <Tag title="High" $color={styles.colors.orange600} />;
        case 4:
            return <Tag title="Urgent" $color={styles.colors.red600} />;
        default:
            return null;
    }
}

const TasksTable: FunctionComponent<ITasksTableProps> = () => {
    const tableRef = useRef<HTMLDivElement>(null)
    const isSmall = useMediaQuery("(max-width: 768px)")

    const { store: { tasks, tasksFilters, tasksParameters } } = useTasksStore()

    const { selectedTasksIds } = tasksFilters
    const { page, pageSize, total } = tasksParameters

    const { completeSingleTask, setTasksFiltersHandler, removeTaskHandler } = useTasksHandlers()
    const { openSidebar, setDestructiveModal, hideModal, setConfirmationModal } = useAppHandlers()
    const { removeReminderHandler } = useRemindersHandlers()

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

    const { store: { requestLoading }} = useAppStore()
    const [tasksLoading, setTasksLoading ] = useState<boolean>(true)

    const noResultTitle = "No Tasks to Show";
    const noResultDescription = "Create and complete tasks, or assign them to your workspace members, set reminders.";

    useEffect(() => {       
        if('getTasks' in requestLoading) setTasksLoading(requestLoading?.['getTasks'])
    }, [requestLoading]);

    const [columns, setColumns] = useState([
        { header: 'Task name', key: ['titleColumn'], width: '245px', show: true, showSmall: true },
        { header: 'Assignee', key: ['assigneeColumn'], show: true },
        { header: 'Due date', key: ['endColumn'], show: true },
        { header: 'Priority', key: ['priorityColumn'], show: true },
        { header: 'Linked connection', key: ['linkedConnectionColumn'], show: true },
        { header: 'Type', key: ['type'], show: true },
        { header: 'Source', key: ['creatorDetailsColumn'], show: true },
        { header: 'Reminder', key: ['reminderColumn'], show: true },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
    ])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall) 
        setColumns(adjustedColumns)

    }, [tasks, isSmall])

    const setReminderDateFormat = (unixTimestamp: EpochTimeStamp) => {
        const currentUnixTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp

        // Convert Unix timestamp to a Moment.js object
        const momentDate = moment.unix(unixTimestamp);

        // Get the current date as a Moment.js object
        const currentDate = moment.unix(currentUnixTimestamp);

        const startOfday = moment.unix(unixTimestamp).startOf('day');

        // Calculate the difference in days between the two dates
        const daysDifference = startOfday.diff(currentDate.startOf('day'), 'days');

        // Determine the relative date format based on the days difference
        let relativeFormat = '';
        if (daysDifference === 0) {
            relativeFormat = 'Today';
        } else if (daysDifference === 1) {
            relativeFormat = 'Tomorrow';
        } else {
            relativeFormat = momentDate.format('MMM D, YYYY'); // Abbreviated day name
        }

        const formattedTime = momentDate.format('h:mm a');

        return relativeFormat.concat(', ', formattedTime);
    }

    const selectAllCheckboxHandler = () => {
        const selectedTasksIds =!isAllSelected ? tasks.map((task: any) => task._id) : []
        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: selectedTasksIds})
        setIsAllSelected(!isAllSelected)
    }

    const selectCheckboxHandler = (id: any) => {
        const index = selectedTasksIds.indexOf(id)
        if (index !== -1) {
            const updatedIds = selectedTasksIds.filter((selectedId: any) => selectedId !== id);
            setTasksFiltersHandler({...tasksFilters, selectedTasksIds: updatedIds});
        } else {
            const updatedIds = [...selectedTasksIds, id];
            setTasksFiltersHandler({...tasksFilters, selectedTasksIds: updatedIds});
        }
    }

    const selectOnThisPageHadler = () => {
        const selectedTasksIds =!isAllSelected ? tasks.map((task: any) => task._id) : []
        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: selectedTasksIds})
    }

    const clearHandler = () => {
        setIsAllSelected && setIsAllSelected(false)
        setTasksFiltersHandler({...tasksFilters, selectedTasksIds: []})
    }

    const onDeleteTaskHandler = async (taskId: string) => {
        setDestructiveModal({
            headingText: "Delete task",
            descriptionText: "Are you sure you want to delete this task?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                await removeTaskHandler(taskId)
            }
        })
    }

    const onCompeteTaskHandler = async (taskId: string) => {
        await completeSingleTask(taskId)
    }
    return (
        <Table
            ref={tableRef}
            pageLoading={!tasks?.length && tasksLoading ? pageSize : undefined}
            noDataPage={<EmptyPage svgType='taskEmpty' title={noResultTitle} label={noResultDescription} />}
            columns={[
                {
                    header:
                    <SelectAllCheckbox
                        isCheckboxChecked={selectedTasksIds.length > 0 || (isAllSelected ?? false)}
                        pageSize={pageSize}
                        total={total}
                        paginated={tasks.length}
                        isAllSelected={isAllSelected ?? false}
                        selected={selectedTasksIds.length}
                        label={'tasks'}
                        selectAllHandler={selectAllCheckboxHandler}
                        selectOnThisPageHadler={selectOnThisPageHadler}
                        clearHandler={clearHandler}
                    />,
                    key: ['checkbox'],
                    width: '36px',
                    showSmall: true
                },

                ...columns
            ]}
            data={tasks.map((task: any) => {
                return ({
                    object: {...task},
                    onRowClick: () => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { task }),
                    checkbox: <StyledCheckbox checked={selectedTasksIds.some((selectedId: any) => selectedId === task._id)} onClickHandler={(e: any) => { e.stopPropagation(); selectCheckboxHandler(task._id) }} checkboxId={task._id} />,
                    titleColumn:
                        <StyledColumn>
                            <CheckMark $completed={task.completed} onClick={(e: any) => { e.stopPropagation(); onCompeteTaskHandler(task._id) }}>
                                {/* SvgFragment hover wont change color */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.16321 11.2024L12.5299 6.06189C12.7121 5.88731 12.7121 5.60429 12.5299 5.42973L11.8699 4.79757C11.6877 4.62302 11.3922 4.62302 11.21 4.79757L6.83324 8.98985L4.78985 7.03257C4.60761 6.858 4.31212 6.858 4.12988 7.03257L3.46993 7.66473C3.28769 7.8393 3.28769 8.12233 3.46993 8.29689L6.50326 11.2024C6.68548 11.377 6.98096 11.377 7.16321 11.2024Z" fill="white" />
                                </svg>
                            </CheckMark>
                            <Column>
                                <Text $bold $ellipsis='1'>{task.title}</Text>
                                <Text $lighter $ellipsis='3'>{task.value}</Text>
                            </Column>
                        </StyledColumn>,
                    endColumn: task.end === 0 ? (
                        <Text $lighter>No Due Date</Text>
                    ) : (
                        <StyledColumn alignItems>
                            <StyledText $red={moment() > moment.unix(task.end) && task.end}>
                                {task.end ? moment.unix(task.end).format("MMM D, YYYY") : 'No due date'}
                            </StyledText>
                            {(task.repeating?.unit && task.repeating?.unit !== 'Never') &&
                                <ImageContainer>
                                    <SvgFragment type='repeat' />
                                    <span>This task repeats {task?.repeating?.unit.toLowerCase()}.</span>
                                </ImageContainer>}
                        </StyledColumn>
                    ),
                    priorityColumn: renderTags(task.priority),
                    linkedConnectionColumn: <>{task?.associations?.connections.length ? <LinkedConnectionColumn id={task?.associations?.connections[0]?._id} profile={task?.associations?.connections[0]?.profile} /> : null}</>,
                    assigneeColumn: <>{task?.assigned.length ? <LinkedConnectionColumn id={task?.assigned?.[0]?._id} profile={task?.assigned?.[0]} /> : null}</>,
                    creatorDetailsColumn:
                        <StyledColumn gap='10px'>
                            <ProfilePhoto tooltipMessage={capitalLetters('')} capitalLetters={capitalLetters(task.creatorDetails[0])} />
                            <Column>
                                <Text $ellipsis='0'>{capitalLetters(task.creatorDetails[0], true)}</Text>
                                <Text $lighter>Personal</Text> {/* This is hardcoded needs to be checked */}
                            </Column>
                        </StyledColumn>,
                    ...task.reminders?.length > 0 ? ({
                        reminderColumn:
                            <StyledColumn gap='10px' alignItems>
                                <ImageContainer>
                                    <SvgFragment type='clock' />
                                </ImageContainer>
                                <StyledColumn>
                                    {task.reminders?.length > 0 ? setReminderDateFormat(task.reminders[0]?.end) : ''}
                                    <CloseIcon className='reminder' onClick={(e: any) => {e.stopPropagation(); removeReminderHandler(task.reminders[0]?._id)}}>
                                        <SvgFragment type='close' />
                                    </CloseIcon>
                                </StyledColumn>
                            </StyledColumn>
                    }) : null,
                    actions:
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                object={task}
                                type='task'
                                onDeleteHandler={() => onDeleteTaskHandler(task._id)}
                                onEditHandler={() => { openSidebar(SidebarTypes.TASK_EDIT_CREATE, { task }) }}
                            />
                        </div>,
                    ...task
                })
            })}
            />
    )
}

const StyledColumn = styled(Row)`

    &:hover .reminder {
        visibility: visible;
    }
`
const StyledText = styled(Text) <IStyledText>`
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis; 

    ${({ $red }) => $red && `color: ${styles.colors.red600}`}
    ${({ $associations }) => $associations && `
        color: ${styles.colors.primary600};
        font-weight: 700
    `}
`

const CellContent = styled(Text) <ICellContent>`
    // overflow: hidden;
    // white-space: pre-wrap;
    // text-overflow: ellipsis;
    // margin: 0;

    ${({ $bold }) => $bold ? 'font-weight: bold;' : ''}
    ${({ $blueText }) => $blueText ? `color: ${styles.colors.primary600}; &:hover { color: ${styles.colors.primary400}; }` : ''}
    ${({ $greyText }) => $greyText ? `color: #8c96ad;` : ''}
    ${({ $redText }) => $redText ? `color: ${styles.colors.red600};` : ''}
    ${({ $cursorPointer }) => $cursorPointer ? 'cursor: pointer;' : ''}
    ${({ $inlineBlock }) => $inlineBlock ? 'display: inline-block;' : ''}

    &.linkedin-connection {
        font-weight: 700;
        color: rgb(89, 100, 255);
        cursor: pointer;
    }

    &.workspace-name {
        color: #8C96AD;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 20px;

    }

    &.creator-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 20px;
    }
`
const CheckMark = styled.div<ICheckMark>`
    border-radius: 50%;
    background-color: ${({ $completed }) => $completed ? styles.colors.primary600 : styles.colors.black300};
    width: 16px;
    height: 16px;
    display: flex;
    margin-right: 10px;
    cursor: pointer;

    ${({ $completed }) => $completed ? 'pointer-events: none;' : ''}

    &:hover { background-color: ${styles.colors.primary600}; }
`
const ImageContainer = styled.div`
    cursor: pointer;
    display: flex;

    & span {
        display: none;
        position: absolute;
        background-color: #333951;
        border-radius: 5px;
        z-index: 1000000;
        transform: translate( -40%, -24px);
        padding: 0 5px;
        font-family: NunitoSans;
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        color: white;
        white-space: nowrap;   
     }

     &:hover {
        & span {
            display: block;

        }
     }
`
const CloseIcon = styled.div`
    visibility: hidden;
    margin-bottom: auto;
    cursor: pointer;
    & svg {
        height: 12px;
        width: 12px;
    }

    &:hover {
        path { fill: ${styles.colors.primary400} }
    }
`
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`
export default TasksTable