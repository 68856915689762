import React, { FunctionComponent, SyntheticEvent } from "react"
import profileImage from '../assets/img/profile-photo.png'
import styled from 'styled-components'
import { useAuthStore } from "../stores/auth/useAuthStore"
import { styles } from "../styles/themes/style"
import TooltipWrapper from "./TooltipWrapper"


interface IProfilePhotoProps {
    tooltipMessage?: string
    source?: string
    capitalLetters?: string
    className?: string
}

const ProfilePhoto: FunctionComponent<IProfilePhotoProps> = ({ tooltipMessage, source, capitalLetters, ...props }) => {

    //hooks
    const { store: { user } } = useAuthStore()
    //Event handlers
    const setFallbackImage = (event: SyntheticEvent<HTMLImageElement>) => {
        let target = event.target as HTMLImageElement
        target.src = profileImage
    }

    return (<TooltipWrapper tooltipText={tooltipMessage ?? capitalLetters?.toString() ?? ''}>
        {!!capitalLetters?.length ? 
            <StyledLetters {...props}>{ capitalLetters }</StyledLetters>
            : <StyledImage
            {...props}
            src={source || profileImage}
            alt='actions-button'
            onError={setFallbackImage}
        />}
    </TooltipWrapper>)
}
const StyledImage = styled.img`
    width: 32px;
    border-radius: 50%;
`

const StyledLetters = styled.span`  
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    color: ${styles.colors.primary600};
    
    background-color: ${styles.colors.primary100};

    &.connection-version-in-1st {
            color: ${styles.colors.yellow600};
            background-color: #FEF8E7;
    }

    &.connection-version-in-2nd {
        color: ${styles.colors.primary600};
        background-color: ${styles.colors.primary100};
    }

    &.connection-version-imported {
            color: ${styles.colors.black300};
            background-color: ${styles.colors.uiBackground};
    }

    &.connection-version-in-regular {
            color: ${styles.colors.black300};
            background-color: ${styles.colors.uiBackground};
    }

    &.connection-version-in-prospect {
            color: ${styles.colors.primary100};
            background-color: ${styles.colors.blue200};
    }
`
export default ProfilePhoto