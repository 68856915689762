import React, { FunctionComponent, useEffect, useState} from 'react'
import styled from 'styled-components'
import { styles } from '../../../styles/themes/style'
import SvgFragment from '../../../fragments/SvgFragment'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { IUserWorkspace } from '../../../interfaces/response/workspace'
import { SidebarTypes } from "../../../interfaces/store/appStore";
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import Badge from '../../../components/Badge'
import { useAppStore } from '../../../stores/application/useAppStore'
import Column from '../../../components/Column'
import LoaderCell from '../../../components/LoaderCell'
import TooltipWrapper from '../../../components/TooltipWrapper'


interface INavigationRailProps { }

const RailPreloader: FunctionComponent<{ rowNumber: number }> = ({ rowNumber }) => (
    <PreloaderContainer>
        <Column gap='3px' >
            {Array(rowNumber).fill(undefined).map((_, index) => (
                <PreloaderRow key={index}>
                    <LoaderCell $width="37px" $height="37px" />
                </PreloaderRow>
            ))}
        </Column>
    </PreloaderContainer>
);

const NavigationRail: FunctionComponent<INavigationRailProps> = () => {
    const { store: { userWorkspaces, workspace } }  = useAuthStore()
    const { changeUserWorkspace } = useAuthHandlers()
    const { openSidebar } = useAppHandlers()
    const { store: { requestLoading }} = useAppStore()
    const [ workspacesLoading, setWorkspacesLoading ] = useState<boolean>(true)

    const onCreateWorkspace = React.useCallback(() => {
        openSidebar(SidebarTypes.WORKSPACE_CREATE)
    }, []); 

    useEffect(() => {
        if('getUserWorkspaces' in requestLoading) setWorkspacesLoading(requestLoading?.['getUserWorkspaces'])
    }, [requestLoading]);
    
    return (
        <Container>
            {workspacesLoading ? (
                <RailPreloader rowNumber={5} />
            ) : (
                    <>
                        {userWorkspaces?.map((userWorkspace: IUserWorkspace) => 
                            <Badge key={userWorkspace.workspaceId} relative top="8px" left="25px" number={userWorkspace.selected ? userWorkspace.notifications.unread : 0}>
                                <TooltipWrapper tooltipText={userWorkspace?.name}>
                                    <WorkspaceBox key={userWorkspace.workspaceId} selected={ userWorkspace.workspaceId === workspace?._id } onClick={() => changeUserWorkspace(userWorkspace)}>{ userWorkspace?.name.slice(0,2) }</WorkspaceBox>
                                </TooltipWrapper>
                            </Badge>
                        )}
                        <Add onClick={onCreateWorkspace} ><SvgFragment type={'plus'} /></Add>
                    </>
                )
            }
        </Container>
    );
};

const PreloaderContainer = styled.div`
    width: 100%;
`;

const PreloaderRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    background: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const Container = styled.div`
    width: ${styles.lengths.railWidth};
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: calc(100vh - ${styles.lengths.navbarHeight});
    background: ${ ({ theme: { secondaryColor }}) => secondaryColor };
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 575px) {
        width: 100vw;
        height: 70px;
        flex-direction: row;
        position: relative;
        gap: 10px;
        padding: 0 16px;
    }
`

const WorkspaceBox = styled.div<{selected?: boolean}>` 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto; 
    width: 34px;
    height: 34px;
    border-radius: 4px;
    background: ${({theme: { primary }}) => primary ? styles.colors.disabledState : styles.colors.black400 };
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 0;
    cursor: pointer; 
    text-transform: uppercase;
    text-decoration: none;
    color: ${styles.colors.white};

    &:hover {
        color: ${styles.colors.white};
        outline: 1px solid ${ ({ theme: { quinaryColor }}) => quinaryColor };
        outline-offset: 2px;
    }

    ${({ selected }) => selected && `
        background: ${styles.colors.primary600};
        outline: 1px solid ${styles.colors.primary600} !important;
        outline-offset: 2px;
    `}
`;

const Add = styled.div` 
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto; 
    width: 40px;
    height: 40px;
    border-radius: 4px;
    position: relative; 
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    line-height: 0; 
    text-align: center;
    margin-top: 16px;
    background: ${({theme: { quinaryColor }}) => quinaryColor};

    .svg {
        width: 15px;
        height: 15px;
        .path {
            stroke: ${({theme: { quaternaryColor }}) => quaternaryColor};
        }
    }

    &:hover {
        background: ${({theme: { tertiaryColor }}) => tertiaryColor};

        & .workspace-tooltip {
            display: inline-block;
        }
    }
`;

export default NavigationRail
