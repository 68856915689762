import { ITag } from "../models/tag";

//Enums
export enum ActionType {
  SET_TAGS = "SET_TAGS",
  SET_TAGS_FILTER = "SET_TAGS_FILTER",
  RESET_STORE = "RESET_STORE",
  RESET_TAGS_PAGINATION = "RESET_TAGS_PAGINATION",
  SET_TAGS_PARAMETERS = "SET_TAGS_PARAMETERS",
}

//Interfaces
export type ITagsFilterState = {
  searchByName: string;
  usersMutualCondition: string;
  selectedUsersMutual: string[];
  tagAccessType: object;
};

//States
export type TTagsState = {
  tags: ITag[];
  tagsFilter: ITagsFilterState;
  tagsParameters: TTagsParametersState;
};

export type TTagsParametersState = {
  page: number;
  pageSize: number;
  total: number;
};

interface ISetTagsAction {
  type: ActionType.SET_TAGS;
  payload: TTagsState["tags"];
}

interface ISetTagsFilterAction {
  type: ActionType.SET_TAGS_FILTER;
  payload: TTagsState["tagsFilter"];
}

interface ISetTagsParametersAction {
  type: ActionType.SET_TAGS_PARAMETERS;
  payload: TTagsState["tagsParameters"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

interface IResetTagsPaginationAction {
  type: ActionType.RESET_TAGS_PAGINATION;
}

export type TTagAction =
  | ISetTagsAction
  | ISetTagsFilterAction
  | ISetTagsParametersAction
  | IResetTagsPaginationAction
  | IResetStoreAction;
