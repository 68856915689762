import { FunctionComponent, ReactNode } from "react";
import { styles } from "../styles/themes/style";
import styled from "styled-components";
import Text, { ITextProps } from "./Text";
import SvgFragment from "../fragments/SvgFragment";

interface IStyledActionTextProps extends ITextProps {
    danger?: boolean,
    cancel?: boolean,
    variable?: boolean,
    invertedVariable?: boolean,
    $disabled?: boolean,
    className?: string,
}

interface IActionTextProps extends IStyledActionTextProps {
    onClickHandler: (e: any) => void;
    icon?: string;
}

const ActionText: FunctionComponent<IActionTextProps> = ({ children, onClickHandler, $disabled, danger, cancel, variable, invertedVariable, icon, className, ...props }) => {

  return (
      <StyledActionText className={className} danger={danger} cancel={cancel} $disabled={$disabled} variable={variable} invertedVariable={invertedVariable} onClick={(e) => !$disabled && onClickHandler(e)}>
          {icon && <IconContainer>
            <SvgFragment type={icon} />
            </IconContainer> }
          <Text $bold {...props}>
              {children}
          </Text>
      </StyledActionText>
  )
}

const StyledActionText = styled.div<IStyledActionTextProps>`
    display: flex;
    align-items: center;
    span {
        color: ${styles.colors.primary600};
        ${({ danger }) => danger && `color: ${styles.colors.red600};`}
        ${({ cancel }) => cancel && `color: ${styles.colors.black300};`}
        ${({ variable, theme: { primaryColor } }) => variable && `color: ${primaryColor};`}
        ${({ invertedVariable, theme: { secondaryColor } }) => invertedVariable && `color: ${secondaryColor};`}
    }

    &:hover{
        cursor: pointer;
        span { 
            color: ${styles.colors.primary400}; 
             ${({ danger }) => danger && `color: ${styles.colors.red500};`}
        }
    } 
    &:focus{
        span { color: ${styles.colors.primary300}; }
        ${({ danger }) => danger && `color: ${styles.colors.red400};`}
    }

    ${({ $disabled }) => $disabled && `
        cursor: default;
        span, &:hover span, &:focus span{
            cursor: default;
            color: ${styles.colors.disabledState};
        }
    `}
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

export default ActionText;
