import moment from "moment";
import { ITaskResponse } from "../../../interfaces/response/task";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useTasksActions } from "../../../stores/tasks/useTasksActions";
import { useTasksStore } from "../../../stores/tasks/useTasksStore";
import { useTaskRequest } from "../../apis/tasks/useTasksRequest";
import { ITasksFiltersState } from "../../../interfaces/store/taskStore";
import { useUserRequest } from "../../apis/user/useUserRequest";
import { useAuthActions } from "../../../stores/auth/useAuthActions";

export const useTasksHandlers = () => {

    const { getTasks, updateTask, createTask, bulkUpdateTasks, bulkRemoveTasks, removeTask } = useTaskRequest()
    const { setTasks, setTasksFilters, setTasksParameters, resetTasksPagination, resetTasksStore } = useTasksActions()
    const { getUserOnboardingTasksState } = useUserRequest()
    const { store: { workspace } } = useAuthStore()
    const { setExtensionInboxTaskId } = useAuthActions()
    const { store: { tasks, tasksFilters, tasksParameters } } = useTasksStore()

    const { selectedTasksIds } = tasksFilters

    
    const getAllTasksHandler = async () => {
        const params = {
            workspaces: [workspace?._id ?? null],
            page: tasksParameters.page,
            pageSize: tasksParameters.pageSize,
            sort: tasksParameters.sort,
            total: tasksFilters.total,
            completed: tasksFilters.completed,
            endFrom: moment(tasksFilters.endFrom).unix(),
            endTo: moment(tasksFilters.endTo).unix(),
            status: tasksFilters.status.value,
            priority: tasksFilters.priority.map((item: any) => item.value),
            ...tasksFilters.type.value !== 'all' && {type: tasksFilters.type.value},
            ...tasksFilters.repeating.value !== 'all' && {repeatingUnit: tasksFilters.repeating.value}
        }

        const result = await getTasks(params)
        setTasks(result?.response ?? [])

        setTasksParameters(result?.metadata)
    }

    const getHomePageTasks = async (isForbidden = false): Promise<ITaskResponse[]> => {
        const result = await getTasks({
            completed: false,
            status: "outstanding",
            isForbidden,
            workspaces: [workspace?._id ?? null],
            byLoggedUser: true,
            page: 1,
            pageSize: 5,
            total: false
        })
        return result?.response ?? []
    };

    const getConnectionTasks = async (connectionId: any): Promise<ITaskResponse[]> => {
        const result = await getTasks({
            completed: false,
            status: "outstanding",
            workspaces: [workspace?._id ?? null],
            connections: connectionId
        })
        return result?.response ?? []
    };

    const completeSingleTask = async (id: string, completed = true, displayToast = true) => {
        const result = await updateTask(id, {
            singleTaskComplete: true,
            completed,
            displayToast
        })
        //if (result && completed) console.log("display toast") //todo: display unde toast, it should take the parameter id, and call this same method on the undo, with complete set to false

        const newTasks = tasks.filter((item: any) => item._id !== id)
        
        setTasks(newTasks)
        //setInitialTasksFiltersHandler({...tasksFilters, total: total - 1}) //todo: acko refetch this
        
        return result
    }

    const updateTaskHandler = async (id: string, task: any) => {
        const result = await updateTask(id, task)
        if(result) {
            const updatedTasks = tasks.map((item: any) => {
                return item._id === id ? { ...result } : item
            })
            setTasks(updatedTasks)
        }
    }

    const bulkUpdateTasksHandler = async (params: any) =>{
        let body = {}

        if(params?.isBulkComplete) {
            body = {
                allSelected: false,
                data: {completed: true, isCompletedBulk: true},
                ids: selectedTasksIds
            }
        } else {
            body = {
                allSelected: false,
                data: params,
                ids: selectedTasksIds
            } 
            setTasksFiltersHandler({...tasksFilters, selectedTasksIds: []})
        }

        const result = await bulkUpdateTasks(body)
        if(result) {
            const newTasks = tasks.map((task: any) => {
                if (selectedTasksIds.includes(task._id)) {
                    return {
                        ...task,
                        ...params,
                    }
                }
                return task
            })
            setTasks(newTasks)
        }
    }

    const bulkRemoveTasksHandler = async () =>{
        const selectedTasks = tasks.filter((item: any) => selectedTasksIds.includes(item._id))

        const body = {
            allSelected: false,
            selectedTasks: selectedTasks
        }
        const result = await bulkRemoveTasks(body)
        if(result?.success) {
            await getAllTasksHandler()
            return result
        }
    }

    const createTaskHandler = async (body: any) => {
        const result = await createTask(body)
        if(result?.success) {
            await getAllTasksHandler()
            return result
        }
    }

    const setTasksFiltersHandler = (payload: ITasksFiltersState) => {
        setTasksFilters(payload)
    }

    const setTasksParametersHandler = (payload: ITasksFiltersState) => {
        setTasksParameters(payload)
    }

    const removeTaskHandler = async (taskId: string) => {
        const result = await removeTask(taskId)
        if(result?.success) {
            await getAllTasksHandler()
            return result
        }
    }

    const getAndResolveTasksHandler = async (tasks: ITaskResponse[]) => {
        const result = await getUserOnboardingTasksState()
        if(result && tasks.length) {
            tasks.forEach(async (task: ITaskResponse) => {
                if(task.isForbidden && task.title === 'Create Tasks' && result.isTaskApplied) {
                    await completeSingleTask(task._id, true, false)
                }
                if(task.isForbidden && task.title === 'Create Tags' && result.isTagApplied) {
                    await completeSingleTask(task._id, true, false)
                }
                if(task.isForbidden && task.title === 'Sync your Linkedin connections' && result.isSynced) {
                    await completeSingleTask(task._id, true, false)
                }
                if(task.isForbidden && task.title === 'Create Notes' && result.isNoteApplied) {
                    await completeSingleTask(task._id, true, false)
                }
                if(task.isForbidden && task.title === 'Check your extension inbox') {
                    await setExtensionInboxTaskId(task._id)
                }
            })
        }
    }

    const numberOfTasksFiltersHandler = () => {
        let numberOfFilters = 0

        if(tasksFilters?.status?.value !== 'outstanding') numberOfFilters += 1
        if(tasksFilters?.type?.value !== 'all') numberOfFilters += 1
        if(tasksFilters?.repeating?.value !== 'all') numberOfFilters += 1
        if(tasksFilters?.endFrom || tasksFilters?.endTo) numberOfFilters += 1
        if(tasksFilters?.priority?.length) numberOfFilters += 1

        return numberOfFilters
    }

    const setClearTasksFilterHandler = () => {
        resetTasksPagination()
    }

    const resetTasksStoreHandler = () => {
        resetTasksStore();
    }

    return {
        getAllTasksHandler,
        getHomePageTasks,
        completeSingleTask,
        updateTaskHandler,
        createTaskHandler,
        setTasksFiltersHandler,
        setTasksParametersHandler,
        bulkUpdateTasksHandler,
        bulkRemoveTasksHandler,
        removeTaskHandler,
        getAndResolveTasksHandler,
        getConnectionTasks,
        numberOfTasksFiltersHandler,
        setClearTasksFilterHandler,
        resetTasksStoreHandler
    };
}
