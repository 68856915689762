import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import CreatableAsyncSelect from '../components/select/CreatableAsyncSelect'
import { useTagsRequest } from '../services/apis/tags/useTagsRequest'
import debounce from 'lodash.debounce'
import Tag from '../components/Tag'
import Row from '../components/Row'
import SvgFragment from './SvgFragment'
import Text from '../components/Text'
import { styles } from '../styles/themes/style'
import Column from '../components/Column'
import FormControl from '../components/FormControl'
import ColorSelectDropdownFragment from './ColorSelectDropdownFragment'
import { useAppHandlers } from '../services/actions/app/useAppHandlers'
import PrivateCheckBoxControl from './PrivateCheckBoxControl'
import { ITag } from '../interfaces/models/connection'
import { useAuthStore } from "../stores/auth/useAuthStore"
import AppTag from './AppTag'

interface IApplyRemoveTagsSelectProps {
    prefilledValue?: ITag[],
    onChangeHandler?: (e: any) => void
}

export interface IEditableTag {
    _id?: string,
    color?: string,
    title?: string,
    value?: string,
    label?: string,
    private?: boolean,
    new?: boolean,
    isForbidden?: boolean
}

const ApplyRemoveTagsSelect: FunctionComponent<IApplyRemoveTagsSelectProps> = ({ prefilledValue, onChangeHandler }) => {

    const { getTagsPaginate } = useTagsRequest();
    const { setCustomColorModalHandler } = useAppHandlers();
    const { store: { workspace} } = useAuthStore()
    const [ value, setValue ] = useState<IEditableTag[]>([])
    const [ selected, setSelected ] = useState<IEditableTag | undefined>(undefined)

    useEffect(() => {
        setValue(prefilledValue?.map(tag => ({
            ...tag,
            value: tag.title,
            label: tag.title
        })) ?? [])
    }, [])

    useEffect(()=>{
        onChangeHandler &&  onChangeHandler(value)
    }, [value])

    const onRemove = useCallback((option: IEditableTag) => {
        if(!option.isForbidden){
            const valueNew = value.filter(item => item.title !== option.title); 
            setValue(valueNew)
            const lastTag = valueNew && valueNew.length ? valueNew[valueNew.length - 1] : undefined; 
            setSelected(lastTag?.new ? lastTag : undefined)
        }
    }, [value])

    const onChange = useCallback((values: IEditableTag[]) => {
        const valueNew = [...values]
        setValue(valueNew)
        const lastTag = valueNew && valueNew.length ? valueNew[valueNew.length - 1] : undefined; 
        setSelected(lastTag?.new ? lastTag : undefined)
    }, [value])

    const onCreate = useCallback((newTitle: string) => {
        const newTag = {
            color: styles.colors.primary600,
            title: newTitle,
            private: true,
            new: true,
            label: newTitle,
            value: newTitle
        }
        setValue([...value, newTag])
        setSelected(newTag)
    }, [value])

    const updateSelected = useCallback((newSelected: IEditableTag) => {
        let newValue = value.map((tag: IEditableTag) => newSelected.title === tag.title ? { ...newSelected } : tag)
        setSelected({ ...newSelected })
        setValue([ ...newValue ])
    }, [selected, value])

    const loadOptionsHandler = debounce(async (inputValue: string, callback: any) => {
        if(inputValue.trim())
        {
            try{
                let tagsData = await getTagsPaginate({page: 1, pageSize: 5, searchByName: inputValue});
                //todo: filter for everything but forbidden
                

                tagsData = tagsData ? tagsData.tags.filter((tag: ITag) => !tag.isForbidden) : []

                callback(tagsData?.map((tag: ITag) => ({
                    ...tag,
                    label: tag.title,
                    value: tag.title
                })))
            }
            catch(error){
                callback([])
            }
        }    
    }, 500)

    return <Column gap='10px'>
                <CreatableAsyncSelect 
                isSearchable
                isMulti
                placeholder = {'Type tag name...'}
                maxLength={30}
                onChange = {(e: any) => onChange(e)}
                value = {value}
                onCreateOption= {(e) => onCreate(e)}
                loadOptions = { loadOptionsHandler }
                components={{
                    DropdownIndicator: null,
                    ClearIndicator: () => null,
                    Option: (itemProps: any) => {
                        return (
                            <StyledOption {...itemProps.innerProps}>
                            {!itemProps.data.__isNew__ ? <Tag
                                $color={itemProps.data.color}
                                title={itemProps.data.label}
                                /> : <CreateLabel alignItems gap="5px"> 
                                <SvgFragment type='plus' />
                                <Text $lighter>Create <Text $bold>{itemProps.data.value}</Text> tag</Text>
                            </CreateLabel>}
                            </StyledOption>
                        )
                    }, 
                    MultiValue: (itemProps: any) => {
                        return (
                            <AppTag 
                                tag={{...itemProps.data, title: itemProps.data.label}} 
                                onDestructiveClickHandler={!itemProps.data.isForbidden ? () => onRemove(itemProps.data) : undefined}
                                />)
                    }, 
                }}
            />
            {selected && 
            <Column gap="10px">
                <FormControl>
                    <Text $label>{'Color'}</Text>
                    <ColorSelectDropdownFragment 
                        color={selected?.color}
                        onColorChangedHandler={ (color: string) => updateSelected({...selected, color}) }
                        openCustomColorModalHandler={() => setCustomColorModalHandler(selected?.color ?? styles.colors.primary600, (color: string) => updateSelected({...selected, color}))}
                    />
                </FormControl>
                { 
                workspace.isBusiness && <PrivateCheckBoxControl
                    type='tag'
                    value={selected.private ?? true}
                    onChangeHandler={(isPrivate: boolean) => updateSelected({...selected, private: isPrivate})}
                />
                }
            </Column>}
        </Column>
}

const CreateLabel = styled(Row)`
    background: transparent;
    padding: 3px;
    .svg {
        width: 20px;
    }
`

const StyledOption = styled.div`
    padding: 6px 10px;
`

export default ApplyRemoveTagsSelect
