import { FunctionComponent, useCallback, FormEvent, useEffect, useState, useRef } from "react";
import _ from 'lodash';
import styled from "styled-components";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from '../../components/select/Select';
import FormControl from '../../components/FormControl'
import { useConnectionsHandlers } from "../../services/actions/connections/useConnectionsHandlers";
import DateComponent from "../../components/DateComponent";
import { useConnectionsStore } from "../../stores/connections/useConnectionsStore";
import ActionText from "../../components/ActionText";
import Column from '../../components/Column'
import AdvancedFilterFragment from "../AdvancedFilterFragment";
import { ILanguage, ITag, IUser } from "../../interfaces/models/connection";
import { industryOptions } from '../../assets/utils/industryOptions'
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import Row from "../../components/Row";
import { useNotesHandlers } from "../../services/actions/notes/useNotesHandlers";
import ProfilePhoto from "../../components/ProfilePhoto";
import Tag from "../../components/Tag";
import MinMaxRangeDropdown from "../dropdowns/MinMaxRangeDropdown";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import AppTag from "../AppTag";

interface IFilterConnectionsFormProps { }

const FilterConnectionsForm: FunctionComponent<IFilterConnectionsFormProps> = () => {
    const textRef = useRef<HTMLDivElement>(null)

    const { setConnectionsFilterHandler, setClearConnectionsFilterHandler } = useConnectionsHandlers();
    const {store: { connectionsFilter }} = useConnectionsStore();
    const { store: { workspace} } = useAuthStore()
    const { store: {connectionsFilter: {
        connectedAtFrom, 
        connectedAtTo, 
        lastMessagedFrom, 
        lastMessagedTo, 
        birthDateFrom, 
        birthDateTo, 
        selectedTags, 
        selectedExcludeTags, 
        tagCondition, 
        selectedLanguages, 
        languageCondition, 
        usersMutualCondition, 
        selectedUsersMutual,
        searchByFollowers,
        searchByMaxFollowers
    }, 
        connectionFilterOptions: {tagFilterOptions, languageFilterOptions, excludeTagFilterOptions}} } = useConnectionsStore()
    const { workspaceUsersPublicAllHandler } = useNotesHandlers();
    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])

    const { openSidebar, closeSidebar } = useAppHandlers()

    const inputTitleLanguages = "Languages";
    const inputTitleTags = "Tags";
    const inputTitleExcludeTags = "Exclude Tags";
    const inputTitleFollowers = "Number of Followers";
    const inputTitleMutualConnections = "Shared Connections";
    const infoTitleLanguages = "Choose whether you want to get items that have all the selected languages (AND) or any of the selected languages (OR).";
    const infoTitleTags = "Choose whether you want to get items that have all the selected tags (AND) or any of the selected tags (OR).";

    const options = [
        { value: 'shortText', label: 'Text field', component: <Text>Text field</Text>, tooltip: 'e.g. John Doe' },
        { value: 'timestamp', label: 'Date', component: <Text>Date</Text>, tooltip: 'e.g 2024-05-24 11:00' },
        { value: 'number', label: 'Number', component: <Text>Number</Text>, tooltip: 'e.g. 12345' },
        { value: 'boolean', label: 'True/False', component: <Text>True/False</Text>, tooltip: 'e.g. True or False' }
    ]

    const hiddenConnectionsOptions = [
        { value: 'no', label: 'No' },
        { value: 'yes', label: 'Yes' },
        { value: 'all', label: 'All' }
    ];

    const contactInfoOptions = [
        { value: 'all', label: 'All' },
        { value: 'yes', label: 'With Contact Info' },
        { value: 'no', label: 'Without Contact Info' },  
    ];

    const notesOptions = [
        { value: 'no', label: 'No' },
        { value: 'yes', label: 'Yes' },
        { value: 'all', label: 'All' }
    ];

    useEffect(() => {
        workspaceUsersHandler()
    }, [])

    const updateForm = useCallback((control: any, newValue: string) => {
        const updatedFilter = {
            ...connectionsFilter,
            [control]: newValue
        };

        setConnectionsFilterHandler(updatedFilter);
    }, [connectionsFilter, setConnectionsFilterHandler]);

    const updateMinMax = useCallback((controls: { [key: string]: number }[]) => {
        const updatedFilter = {
            ...connectionsFilter,
            ...controls.reduce((buildingValue, currentValue) => ({...buildingValue, ...currentValue}), {})
        };

        setConnectionsFilterHandler(updatedFilter);
    }, [connectionsFilter, setConnectionsFilterHandler]);

    const resetDateFilter = (controlFrom: any, controlTo: any) => {
        const updatedFilter = {
           ...connectionsFilter,
           [controlFrom]: undefined,
           [controlTo]: undefined
       }; 
       setConnectionsFilterHandler(updatedFilter);
   };

    const saveSavedFiltersHandler = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        openSidebar(SidebarTypes.SAVE_FILTER)
    }, [connectionsFilter]);

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.CONNECTION_FILTER)
    }, [connectionsFilter]);

    const resetFormHandler = useCallback(() => {
        console.log(connectionsFilter)
        //resetConnectionsFilterHandler(updatedFilter); 
        //this action should be in the handlers and as a store action since initial state of the filters, not searches is saved there as a constant
        //and it should also be accessible in the store from other parts of the app

        setClearConnectionsFilterHandler();
    }, [connectionsFilter, setConnectionsFilterHandler]);

    // tags filter
    const setSelectedTags = (newlySelectedTags: ITag[]) => {
        let noTags = false
        newlySelectedTags = newlySelectedTags.filter((selectedTag => {
            if(selectedTag._id === 'noTags') noTags = true
            return selectedTag._id !== 'noTags'
        }))
        const updatedFilter = {
            ...connectionsFilter,
            selectedTags: [...newlySelectedTags],
            noTags
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

     const resetSelectedTags = () => {
        //setNoTagsSelected(false);
        const updatedFilter = {
            ...connectionsFilter,
            selectedTags: [],
            noTags: false
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    const setSelectedExcludeTags = (newlySelectedTags: ITag[]) => {
        const updatedFilter = {
            ...connectionsFilter,
            selectedExcludeTags: [...newlySelectedTags]
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    const resetSelectedExcludeTags = () => {
        const updatedFilter = {
            ...connectionsFilter,
            selectedExcludeTags: []
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    // languages filter 
    const setSelectedLanguages = (newlySelectedLanguages: string[]) => {
        const updatedFilter = {
            ...connectionsFilter,
            selectedLanguages: [...newlySelectedLanguages]
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

     const resetSelectedLanguages = () => {
        //setNoTagsSelected(false);
        const updatedFilter = {
            ...connectionsFilter,
            selectedLanguages: []
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    const setSelectedUsersMutual = (newlySelectedUsers: IUser[]) => {
        const updatedFilter = {
            ...connectionsFilter,
            selectedUsersMutual: [...newlySelectedUsers]
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    const resetSelectedUsersMutual = () => {
        const updatedFilter = {
            ...connectionsFilter,
            selectedUsersMutual: []
        }; 
        setConnectionsFilterHandler(updatedFilter);
    };

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '',
            getAll: 'yes' 
        })
        setWorkspaceUsers(result)
    }

    return (
        <StyledForm>
            <section>
                <Column gap="8px">
                    <Column>
                        <Text $label $black>Action based filters</Text>
                    </Column>
                    {/* All is former or, and mutual is and, the default behaviour is to show all */}
                    { 
                    <><AdvancedFilterFragment
                        filterOptions={workspaceUsers.map((user: IUser) => ({
                            value: `${user.firstName} ${user.lastName}`,
                            label: <Row alignItems gap="10px">
                                    <ProfilePhoto capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                                    <Column>
                                        <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                                        <div style={{width: textRef?.current ? `${textRef?.current.offsetWidth}px` : undefined}}>
                                            <Text ref={textRef} $ellipsis="0">{user?.email}</Text>
                                        </div>
                                    </Column>
                            </Row>,
                            id: user._id,
                            selected: false,
                            object: user
                        }))} 
                        selectedItems={selectedUsersMutual}
                        infoTitle={infoTitleTags}
                        inputTitle={inputTitleMutualConnections}
                        tabs={[{title: 'All', slug: 'Or', selected: usersMutualCondition === 'Or'}, {title: 'Shared', slug: 'And', selected: usersMutualCondition === 'And'}, {title: 'Unique', slug: 'Xor', selected: usersMutualCondition === 'Xor'}]}
                        onSelectedTabHandler={(tab: string) => updateForm('usersMutualCondition', tab)} 
                        onSelectedItemHandler={setSelectedUsersMutual} 
                        onResetSelectionsHandler={resetSelectedUsersMutual} /></>
                    } 
                    <DateComponent
                        title='Date Connected'
                        dateFrom={connectedAtFrom}
                        dateTo={connectedAtTo}
                        dateFromValueChangedHandler={(e: any) =>  updateForm('connectedAtFrom', e)}
                        dateToValueChangedHandler={(e: any) =>  updateForm('connectedAtTo', e)}
                        resetFilter={() => resetDateFilter('connectedAtFrom', 'connectedAtTo')}/>
                    <DateComponent
                        title='Last Messaged'
                        dateFrom={lastMessagedFrom}
                        dateTo={lastMessagedTo}
                        dateFromValueChangedHandler={(e: any) =>  updateForm('lastMessagedFrom', e)}
                        dateToValueChangedHandler={(e: any) =>  updateForm('lastMessagedTo', e)}
                        resetFilter={() => resetDateFilter('lastMessagedFrom', 'lastMessagedTo')}/>
                    <FormControl $orientation="column">
                        <Select 
                            placeholder="Hidden connections"
                            isSearchable={false}
                            options={hiddenConnectionsOptions}
                            value={connectionsFilter?.hiddenConnectionsValue ? {label: 'Hidden connections: ' + connectionsFilter?.hiddenConnectionsValue.label} : undefined}
                            onChange={(e:any) => updateForm('hiddenConnectionsValue', e)}
                        />
                </FormControl>
                </Column>
            </section>
            <section>
                <Column gap="8px">
                    <Column>
                        <Text $label $black>Personal info</Text>
                    </Column>
                    <DateComponent
                        title='Date of Birth'
                        dateFrom={birthDateFrom}
                        dateTo={birthDateTo}
                        dateFromValueChangedHandler={(e: any) =>  updateForm('birthDateFrom', e)}
                        dateToValueChangedHandler={(e: any) =>  updateForm('birthDateTo', e)}
                        resetFilter={() => resetDateFilter('birthDateFrom', 'birthDateTo')}/>
                    <AdvancedFilterFragment
                        filterOptions={languageFilterOptions.map((lang: string) => ({
                            value: lang,
                            label: lang,
                            id: lang,
                            selected: false,
                            object: lang
                        }))} 
                        selectedItems={selectedLanguages.map( (language: string) => ({ _id: language }))}
                        infoTitle={infoTitleLanguages}
                        inputTitle={inputTitleLanguages}
                        tabs={[{title: 'And', slug: 'And', selected: languageCondition !== 'Or'}, {title: 'Or', slug: 'Or', selected: languageCondition === 'Or'}]} 
                        onSelectedTabHandler={(tab: string) => updateForm('languageCondition', tab)}
                        onSelectedItemHandler={setSelectedLanguages} 
                        onResetSelectionsHandler={resetSelectedLanguages} />
                    <FormControl $orientation="column">
                        <Select 
                            placeholder="Contact Info"
                            isSearchable={false}
                            options={contactInfoOptions}
                            value={connectionsFilter?.contactInfo ? {label: 'Contact Info: ' + connectionsFilter?.contactInfo.label} : undefined}
                            onChange={(e:any) => updateForm('contactInfo', e)}
                        />
                    </FormControl>
                    <FormControl $orientation="column">
                        <Select 
                            placeholder="Industry"
                            isSearchable={false}
                            options={industryOptions}
                            value={connectionsFilter?.industry ? {label: 'Industry: ' + connectionsFilter?.industry.label} : undefined}
                            onChange={(e:any) => updateForm('industry', e)}
                        />
                    </FormControl>
                    <MinMaxRangeDropdown
                        prefilledMinValue={searchByFollowers}
                        prefilledMaxValue={searchByMaxFollowers}
                        title={inputTitleFollowers}
                        onChangeHandler={({ minValue, maxValue }: { minValue?: number, maxValue?: number }) => updateMinMax([ ...[ minValue ? { 'searchByFollowers': minValue } : {}], ...[ maxValue ? { 'searchByMaxFollowers': maxValue } : {}] ])}
                        description={<span>Enter the minimum and maximum number <br /> of followers</span>}
                        resetFilter={()=>resetDateFilter('searchByFollowers', 'searchByMaxFollowers')}
                    />
                </Column>
            </section>          
            <section>
                <Column gap="8px">
                    <Column>
                        <Text $label $black>Tags and notes filters</Text>
                    </Column>
                    <AdvancedFilterFragment
                        filterOptions={[ {
                                value: '',
                                label: 'Without Tags',
                                id: 'noTags',
                                selected: false,
                                object: {
                                    _id: 'noTags'
                                }
                            }, 
                            ...tagFilterOptions.map((tag: ITag) => ({
                                value: tag.title,
                                label: <AppTag
                                       tag={tag}
                                    />,
                                id: tag._id,
                                selected: false,
                                object: tag
                        }))]} 
                        selectedItems={[...selectedTags, ...(connectionsFilter.noTags ? [{ _id: 'noTags' }] : []) ]}
                        infoTitle={infoTitleTags}
                        inputTitle={inputTitleTags}
                        tabs={[{title: 'And', slug: 'And', selected: tagCondition !== 'Or'}, {title: 'Or', slug: 'Or', selected: tagCondition === 'Or'}]}
                        onSelectedTabHandler={(tab: string) => updateForm('tagCondition', tab)}
                        onSelectedItemHandler={setSelectedTags} 
                        onResetSelectionsHandler={resetSelectedTags}
                    />
                    <AdvancedFilterFragment
                        filterOptions={excludeTagFilterOptions.map((tag: ITag) => ({
                                value: tag.title,
                                label: <AppTag
                                        tag={tag}
                                    />,
                                id: tag._id,
                                selected: false,
                                object: tag
                        }))} 
                        selectedItems={selectedExcludeTags}
                        infoTitle={infoTitleTags}
                        inputTitle={inputTitleExcludeTags}
                        tabs={[{title: 'And', slug: 'And', selected: tagCondition !== 'Or'}, {title: 'Or', slug: 'Or', selected: tagCondition === 'Or'}]} 
                        onSelectedTabHandler={(tab: string) => updateForm('tagCondition', tab)}
                        onSelectedItemHandler={setSelectedExcludeTags} 
                        onResetSelectionsHandler={resetSelectedExcludeTags} />   
                    <FormControl $orientation="column">
                        <Select 
                            placeholder="Notes"
                            isSearchable={false}
                            options={notesOptions}
                            value={connectionsFilter?.note ? {label: 'Note: ' + connectionsFilter?.note.label} : undefined}
                            onChange={(e:any) => updateForm('note', e)}
                        />
                    </FormControl>
                </Column>
            </section>                     
            <Row gap="10px" spaceBetween>
                <ActionText danger onClickHandler={() => resetFormHandler()}>Clear filters</ActionText>
                <Row gap="10px" alignItems>
                    <ActionText onClickHandler={(e: any) => saveSavedFiltersHandler(e)}>Save filters</ActionText>
                    <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                </Row>
            </Row>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`
export default FilterConnectionsForm