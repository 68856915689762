import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled, { DefaultTheme, useTheme } from "styled-components"
import FormControl from '../../../components/FormControl'
import { IFormProperty } from '../../../interfaces/common';
import Button from '../../../components/Button';
import Select from '../../../components/select/Select';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';

interface ISurveySectionProps { }

interface ISurveyFormState extends Record<string,any> { 
    leadDeltaUsage: IFormProperty;
    teamSize: IFormProperty;
    hearAboutUs: IFormProperty;
}

const usingLeadDeltaForOptions = [
    { label: 'With others: collaborate with your whole team' },
    { label: 'For myself: all my relationships in one place' }
]

const teamSizeOptions = [
    { label: 'Me, myself and I' },
    { label: '2-5' },
    { label: '6-10' },
    { label: '11-50' },
    { label: '51-100' },
    { label: '101+' }
]

const hearAboutUsOptions = [
    { label: 'A friend or co-worker recommendation' },
    { label: 'An influencer I follow' },
    { label: 'LinkedIn' },
    { label: 'X (Twitter)' },
    { label: 'Product Hunt' },
    { label: 'Google' },
    { label: 'I read an article' },
    { label: 'AI recommendation' },
    { label: 'You reached out' },
    { label: 'Other' }
]

const SurveySection: FunctionComponent<ISurveySectionProps> = () => {
    const theme: DefaultTheme = useTheme()
    const { saveOnboardingData } = useAuthHandlers()
    

    const formOriginalState: ISurveyFormState = {
        leadDeltaUsage: {
            property: "",
            placeholder: 'Choose',
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length > 0 ? undefined : "This field is required"
                requiredError && this?.errors?.push(requiredError)
                updateErrors('leadDeltaUsage', this?.errors)
                return this?.errors?.length === 0
            }
        },
        teamSize: {
            property: "",
            placeholder: 'Choose',
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length > 0 ? undefined : "This field is required"
                requiredError && this?.errors?.push(requiredError)
                updateErrors('teamSize', this?.errors)
                return this?.errors?.length === 0
            }
        },
        hearAboutUs: {
            property: "",
            placeholder: 'Choose',
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length > 0 ? undefined : "This field is required"
                requiredError && this?.errors?.push(requiredError)
                updateErrors('hearAboutUs', this?.errors)
                return this?.errors?.length === 0
            }
        }
    }

    const [surveyFormState, setSurveyFormState] = useState<ISurveyFormState>({ ...formOriginalState })
    const [surveyFormValid, setSurveyFormValid] = useState<boolean>(false)

    const validateForm = useCallback(async () => {
        const validForm = await surveyFormState?.leadDeltaUsage.isValid() && await surveyFormState?.teamSize.isValid() && await surveyFormState?.hearAboutUs.isValid();
        setSurveyFormValid(validForm);
        return validForm;
    }, [surveyFormState])

    const updateForm = useCallback((control: any, newValue: string) => {
        setSurveyFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], property: newValue.trim()} };
            return newState
        })
    }, [surveyFormState]); 

    const updateErrors = useCallback((control: any, newValue: string[]) => {
        setSurveyFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], errors: [...newValue]} };
            return newState
        })
    }, [surveyFormState]); 

    const submitFormHandler = useCallback(async (e: FormEvent) => {
        e.preventDefault();
		const validForm = await validateForm();
        if(validForm){
            await saveOnboardingData({
                usingLeadDeltaFor: surveyFormState?.leadDeltaUsage?.property,
                teamSize: surveyFormState?.teamSize?.property,
                hearAboutUs: surveyFormState?.hearAboutUs?.property
            })
        }
	}, [surveyFormState]); 
    

    return <Container>
        <StyledForm onSubmit={submitFormHandler}>
            <FormControl $orientation="column" label="How are you planning to use LeadDelta?" error={surveyFormState?.leadDeltaUsage?.errors?.[0]}>
                <Select 
                    placeholder={surveyFormState?.leadDeltaUsage?.placeholder}
                    isSearchable={false}
                    options={usingLeadDeltaForOptions}
                    onChange={(e:any) => updateForm('leadDeltaUsage', e.label)}
                    value={surveyFormState?.leadDeltaUsage?.property?.length ? {label: surveyFormState?.leadDeltaUsage?.property} : undefined}
                />
            </FormControl>
            <FormControl $orientation="column" label="How many people on your team?" error={surveyFormState?.teamSize?.errors?.[0]}>
                <Select 
                    placeholder={surveyFormState?.teamSize?.placeholder}
                    isSearchable={false}
                    options={teamSizeOptions}
                    onChange={(e:any) => updateForm('teamSize', e.label)}
                    value={surveyFormState?.teamSize?.property?.length ? {label: surveyFormState?.teamSize?.property} : undefined}
                />
            </FormControl>
            <FormControl $orientation="column" label="How did you hear about us?" error={surveyFormState?.hearAboutUs?.errors?.[0]}>
                <Select 
                    placeholder={surveyFormState?.hearAboutUs?.placeholder}
                    isSearchable={false}
                    maxHeight={300}
                    options={hearAboutUsOptions}
                    onChange={(e:any) => updateForm('hearAboutUs', e.label)}
                    value={surveyFormState?.hearAboutUs?.property?.length ? {label: surveyFormState?.hearAboutUs?.property} : undefined}
                />
            </FormControl>
            <Button $type='blue' type="submit">{'Continue'}</Button>
        </StyledForm>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-width: 600px;
    padding: 5px;
    overflow-y: auto;
`
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default SurveySection