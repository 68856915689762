import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconStepSuccess } from '../../../assets/img/step-success.svg';
import { ReactComponent as IconStepError } from '../../../assets/img/step-error.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/img/icn-linkedin.svg';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers'
import { ILinkedInUserData } from '../../../interfaces/models/user'
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import InformationBox from '../../../components/InformationBox';
import { useNavigate } from 'react-router-dom';
import ActionText from '../../../components/ActionText';
import ProfilePhoto from '../../../components/ProfilePhoto';
import { styles } from '../../../styles/themes/style';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { useAuthStore } from '../../../stores/auth/useAuthStore';
import { useAppActions } from '../../../stores/application/useAppActions';

interface ILinkedInSectionProps { }

const LinkedInSection: FunctionComponent<ILinkedInSectionProps> = () => {

    const { getLoggedInLIUserHandler, saveLinkedInDataToUser } = useAuthHandlers()
    const { visitLIProfile, LIPagePrefix } = useExternalHandlers()
    const [userLI, setUserLI] = useState<ILinkedInUserData | undefined>(undefined)
    const [notCorrectProfile, setNotCorrectProfile] = useState<boolean>(false)
    const [userInfoMessage, setUserInfoMessage] = useState<string>('')
    const { store: { user } } = useAuthStore()
    const { setOnboardingStep } = useAppActions()
    const navigate = useNavigate();
    
    useEffect(() => {
        checkYourLIConnection()
    }, [])

    const checkYourLIConnection = useCallback(async () => {
        setNotCorrectProfile(false)
        let userLI = await getLoggedInLIUserHandler()
        if(user?.linkedinData && userLI && userLI?.publicIdentifier === user?.linkedinData?.publicIdentifier) {
            setOnboardingStep(-2);
            navigate(AppRoutes.HOME);
        }
        if((user?.linkedinData && userLI && user?.linkedinData?.publicIdentifier)) {
            setUserInfoMessage('Open a new tab, log out of your current LinkedIn account, and log in with the correct one. Then return to this page and check LinkedIn Connection.')//user.publicIdentifier
            setNotCorrectProfile(true)
        }
        if(!userLI) setUserInfoMessage('Open a new Tab and ensure you are logged into your LinkedIn account. Then come back to this page and check LinkedIn Connection.')
        setUserLI(userLI)
    }, [])

    const saveProfileInfo = useCallback(async () => {
        if(userLI) await saveLinkedInDataToUser(userLI)
    }, [userLI])

    return <Container>
        <Row>
            {userLI ? <IconStepSuccess /> : <IconStepError />}
            <Text $label $lighter> Check if you are logged in to your LinkedIn</Text>
        </Row>
        {userLI && <CurrentProfile>
            <LiProfileOverlap>
                <ProfilePhoto source={userLI.profilePicture}/>
                <LiProfileOverlapPhoto><LinkedInIcon /></LiProfileOverlapPhoto>
            </LiProfileOverlap>
            <CurrentProfileName>
                <Text $black>{`${userLI.firstName} ${userLI.lastName}`}</Text>
                <ActionText $breakWord $black onClickHandler={()=> visitLIProfile(userLI.publicIdentifier ?? '')}>{`${LIPagePrefix}${userLI.publicIdentifier}`}</ActionText>
            </CurrentProfileName>
        </CurrentProfile>}
        {!!userInfoMessage.length && <InformationBox message={userInfoMessage}/>}
        {(!userLI || notCorrectProfile) && <Button $type='grey' $SVGtype='reload' onClickHandler={checkYourLIConnection}>{'Check LinkedIn Connection'}</Button>}
        {userLI && !notCorrectProfile && <Button $type='blue' disabled={notCorrectProfile} onClickHandler={()=>saveProfileInfo()}>{'Confirm profile'}</Button>}
        {userLI && !notCorrectProfile && <Row $justifyContentCenter><ActionText cancel $disabled={notCorrectProfile} onClickHandler={()=>setNotCorrectProfile(true)}>No, switch profile</ActionText></Row>}
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    height: 100%;
    max-width: 600px;
    padding: 5px;
`

const Row = styled.div<{$justifyContentCenter?: boolean}>`
    display: flex;
    flex-direction: row;
    gap: 8px;
    ${({ $justifyContentCenter }) => $justifyContentCenter && 'justify-content: center;'}
`;
const LiProfileOverlap = styled.div`
    position: relative;
    max-height: 32px;
`
const LiProfileOverlapPhoto = styled.div`
    position: absolute;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${styles.colors.primary100};
    right: -7px;
    bottom: -3px;
`
const CurrentProfile = styled.div`
    background: ${styles.colors.primary100};
    border: 1px solid ${styles.colors.primary600};
    display: flex;
    gap: 9px;
    padding: 12px;
    border-radius: 8px;
`
const CurrentProfileName = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`
export default LinkedInSection
