import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TemplatesControlBar from '../components/templates/TemplatesControlBar';
import { useTemplateHandlers } from '../../../services/actions/template/useTemplateHandlers';
import TemplatesTable from '../components/templates/TemplatesTable';
import { useTemplateStore } from '../../../stores/templates/useTemplateStore';

interface ITemplatesSectionProps { }

const TemplatesSection: FunctionComponent<ITemplatesSectionProps> = () => {
    const { getTemplatesHandler } = useTemplateHandlers()

    const { store: { templates, templatesParameters, templatesFilters } } = useTemplateStore()

    useEffect(() => {
        getTemplatesHandler()
    }, [templatesParameters.page, templatesParameters.pageSize, templatesFilters])

    return (
        <Container>
            <Column gap='20px'>
                <TemplatesControlBar />
                <TemplatesTable templates={templates} /> 
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

export default TemplatesSection
