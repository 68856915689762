import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Tag from '../components/Tag'
import { styles } from '../styles/themes/style'
import { ITag } from '../interfaces/models/connection'

interface IAppTagProps {
    tag: ITag
    $width?: string
    onDestructiveClickHandler?: (e: any) => void
 }

const AppTag: FunctionComponent<IAppTagProps> = ({ tag, $width, onDestructiveClickHandler }) => {

    let SVGtype

    if (['LinkedIn', 'LinkedIn 1st'].includes(tag.title)) SVGtype = "linkedin"  
    else if (['Imported'].includes(tag.title)) SVGtype = "imported" 
    else if (['CSV'].includes(tag.title)) SVGtype = "csv"
    else if (['Disconnected'].includes(tag.title)) SVGtype = "diconnected"
    else if (!tag.private) SVGtype = "tagCompany"
    else SVGtype = ""
    return  <Tag 
        onDestructiveClickHandler={onDestructiveClickHandler}
        $backgroundColor={SVGtype.length > 0 && tag.isForbidden ? '' : tag.color}
        $borderColor={SVGtype.length > 0 && tag.isForbidden ? styles.colors.primary600 : styles.colors.white} 
        $color={SVGtype.length > 0 && tag.isForbidden ? '' : tag.color} 
        title={tag.title} 
        SVGtype={SVGtype}
        $width={$width} />
}


export default AppTag
