import { FunctionComponent } from "react";
import DropdownMenu from "./dropdown/DropdownMenu";
import Checkbox from "./Checkbox";
import DropdownItem from "./dropdown/DropdownItem";
import styled from "styled-components";
import Row from "./Row";
import { styles } from "../styles/themes/style";

interface ISelectAllCheckboxProps {
    isCheckboxChecked: boolean
    pageSize: number | undefined
    total: number | undefined
    paginated: number | undefined
    isAllSelected: boolean
    selected: number | undefined
    label?: string
    selectAllHandler: (e: any) => void
    selectOnThisPageHadler: (e: any) => void
    clearHandler: (e: any) => void
}

const SelectAllCheckbox: FunctionComponent<ISelectAllCheckboxProps> = ({
    isCheckboxChecked,
    pageSize = 0,
    total = 0,
    paginated,
    isAllSelected,
    selected,
    label,
    selectAllHandler,
    selectOnThisPageHadler,
    clearHandler
}) => {
    

    return (
        <Row alignItems> 
        <DropdownMenu $hideToggle $transparent title={<Checkbox checked={isCheckboxChecked} checkboxId={`select-all-${label}-checkbox`} />} >
            <DropdownItem onClickHandler={selectOnThisPageHadler}>
                    <Label>Select {paginated} on this page</Label>
                </DropdownItem>
            {total > pageSize && 
                <DropdownItem onClickHandler={selectAllHandler}>
                    <Label>Select All {total} {label}</Label>
                </DropdownItem>}
        </DropdownMenu>
        {isCheckboxChecked &&
            <Counter>
                <span>{isAllSelected ? 'ALL' : `${selected} SELECTED`}</span> <div>(<span className='link' onClick={clearHandler} id='clear-selection-button'>CLEAR<span className='description'>Clear Selection</span></span>)</div>
            </Counter>}
        </Row>
    )

}

const Label = styled.span`
    text-transform: none;
    font-weight: 400;
`
const Counter = styled.div`
    position: relative;
    left: 5px;
    z-index: 1003;

    display: flex;
    align-items: center;
    gap: 5px;
    width: 190px;
    height: 16px;

    line-height: 20px;
    font-size: 11px;
    font-weight: bold;
    font-style: NunitoSans;
    letter-spacing: 0.5px;
    color: ${styles.colors.black300};
    background-color: ${styles.colors.uiBackground};

    & span {
        color: ${styles.colors.primary600};
        user-select: none;
        font-size: 12px;
    }
    & span.link {
        position: relative;
        color: #ff6760;
        cursor: pointer;
        
        & span.description {
            display: none;
            position: absolute;
            z-index: 100;
            left: 50%;
            top: -27px;
            transform: translate(-50%, 0);
            padding: 0 2px;
            font-family: NunitoSans;
            font-size: 12px;
            font-weight: normal;
            line-height: 18px;
            color: white;
            background-color: ${styles.colors.black600};
            border-radius: 5px;
            white-space: nowrap;
        }

        &:hover {
            color: rgba(255, 131, 131, 0.54);
            opacity: unset !important;
            & span.description { display: block; }
        }
    }
    
    @media (max-width: 968px) {
        width: 150px;
    }

`
export default SelectAllCheckbox