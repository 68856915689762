
//Enums
export enum ActionType {
  SET_TASKS = "SET_TASKS",
  SET_TASKS_PARAMETERS = "SET_TASKS_PARAMETERS",
  SET_TASKS_FILTERS = "SET_TASKS_FILTERS",
  RESET_STORE = "RESET_STORE",
  RESET_TASKS_PAGINATION = "RESET_TASKS_PAGINATION",
}

//Interfaces
export type ITasksParametersState = {
  page?: number,
  pageSize?: number,
  sort?: string,
  total?: undefined | number
}

export type ITasksFiltersState = {
  total?: boolean,
  completed?: boolean,
  endFrom?: number | null,
  endTo?: number | null,
  status?: object,
  type?: object,
  repeating?: object,
  workspaces?: string[],
  selectedTasksIds: any,
  taskCondition: string,
  priority: object[]
}

//States
export type TTasksState = {
  tasks: any[];
  tasksParameters: ITasksParametersState;
  tasksFilters: ITasksFiltersState
};

interface ISetTasksAction {
  type: ActionType.SET_TASKS;
  payload: TTasksState["tasks"];
}

interface ISetTasksParametersAction {
  type: ActionType.SET_TASKS_PARAMETERS;
  payload: TTasksState["tasksParameters"];
}

interface ISetTasksFiltersAction {
  type: ActionType.SET_TASKS_FILTERS;
  payload: TTasksState["tasksFilters"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

interface IResetTasksPaginationAction {
  type: ActionType.RESET_TASKS_PAGINATION;
}

export type TTasksAction = ISetTasksAction | ISetTasksParametersAction | ISetTasksFiltersAction | IResetStoreAction | IResetTasksPaginationAction;
