import { TCallbackFunction } from "../../../interfaces/common"
import { IUserResponse } from "../../../interfaces/models/user"
import { IAuthData } from "../../../interfaces/store/authStore"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { IResponse, useAxios } from "../useAxios"

export const useAuthRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/auth'

    const getUser = (params?: IAuthData): Promise<IUserResponse> => {
        return sendRequest({
            enabled: params ? !!params.accessToken : !!accessToken,
            slug: 'user',
            url: `${route}/me`,
            method: 'GET',
            ...params && { additionalHeaders: { ...params } }
        })
    };

    const verificationCode = async (body: any) => {
        const response = await sendRequest({
            slug: 'verification-code',
            url: `${route}/verification-code`,
            method: 'POST',
            payload: body,
            all: true
        });

        return response;
    };

    const verificationCodeValidate = async (body: any) => {
        const response = await sendRequest({
            slug: 'verification-code-validate',
            url: `${route}/verification-code/validate`,
            method: 'POST',
            payload: body,
            all: true
        });

        return response;
    };

    const resetPassword = async (body: any) => {
        const response = await sendRequest({
            slug: 'reset-password',
            url: `${route}/reset-password`,
            method: 'POST',
            payload: body,
            all: true
        });

        return response;
    };

    const invitePublic = async (inviteCode: any) => {
        const response = await sendRequest({
            slug: 'invite-public',
            url: `/invites/code/${inviteCode}`
        });

        return response;
    };

    const signInEmail = async (body: any) => {
        const response = await sendRequest({
            slug: 'login-email',
            url: `${route}/login`,
            method: 'POST',
            payload: body,
            displayErrorToast: true
        });

        return response;
    };

    const signInGoogle = async (body: any) => {
        const response = await sendRequest({
            slug: 'login-google',
            url: `${route}/login/google`,
            method: 'POST',
            payload: body,
            displayErrorToast: true
        });

        return response;
    };

    const signUpEmail = async (body: any, other?: any) => {
        const response = await sendRequest({
            slug: 'register-email',
            url: `${route}/register`,
            method: 'POST',
            payload: body,
            displayErrorToast: true,

            ...other
        });

        return response;
    };

    const signUpGoogle = async (body: any, other?: any) => {
        const response = await sendRequest({
            slug: 'register-google',
            url: `${route}/register/google`,
            method: 'POST',
            payload: body,
            displayErrorToast: true,
            ...other
        });

        return response;
    };


    const switchUserWorkspace = async (payload: any) => {
        const response = await sendRequest({
            slug: 'switch-user-workspace',
            url: `${route}/me/context`,
            method: 'PUT',
            payload,
            displayToast: false
        });

        return response;
    };

    return {
        getUser,
        verificationCode,
        verificationCodeValidate,
        resetPassword,
        invitePublic,
        signInEmail,
        signInGoogle,
        signUpEmail,
        signUpGoogle,
        switchUserWorkspace
    };
}