import React, { FunctionComponent, useEffect, useState, useCallback, FormEvent } from 'react'
import styled from "styled-components"
import { isValid } from '../../utils'
import { IFormProperty } from '../../interfaces/common'
import Input from '../../components/Input'
import FormControl from '../../components/FormControl'
import Button from '../../components/Button'
import List from '../../components/list/List'
import InvitationItemFragment from '../../fragments/InvitationItemFragment'
import { useWorkspaceRequest } from '../../services/apis/workspace/useWorkspaceRequest'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'
import { IWorkspace } from '../../interfaces/models/workspace'
import { useWorkspaceHandlers } from '../../services/actions/workspace/useWorkspaceHandlers'
import { useAuthActions } from '../../stores/auth/useAuthActions'
import { SidebarTypes } from '../../interfaces/store/appStore'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import { useWorkspaceUsersStore } from '../../stores/workspaceUsers/useWorkspaceUsersStore'
import { useWorkspaceUsersActions } from '../../stores/workspaceUsers/useWorkspaceUsersActions'

interface ICreateWorkspaceFormProps {
    loginWorkspace?: boolean,
    workspace?: IWorkspace
    invite?: boolean
}

interface ITeamFormState extends Record<string,any> {
    teamName: IFormProperty,
    companyName: IFormProperty,
    websiteUrl: IFormProperty,
    inviteTeammates: IFormProperty
}

const CreateWorkspaceForm: FunctionComponent<ICreateWorkspaceFormProps> = ({loginWorkspace, workspace, invite}) => {
    const { getExistsWorkspace } = useWorkspaceRequest()
    const { createAndLoginToWorkspaceHandler, createWorkspaceHandler } = useAuthHandlers()
    const { updateWorkspaceHandler, sendInvitesHandlers } = useWorkspaceHandlers()
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const { store: { requestLoading } } = useAppStore()
    const { setWorkspace } = useAuthActions()
    const { closeSidebar } = useAppHandlers()

    const formOriginalState: ITeamFormState = {
        teamName: {
            property: "",
            placeholder: "",
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length > 0 ? undefined : "This field is required."
                requiredError && this?.errors?.push(requiredError)
                const data = requiredError ? false : await getExistsWorkspace({name: this?.property})
                data && this?.errors?.push("Name already exists.")
                updateErrors('teamName', this?.errors)
                return this?.errors?.length === 0
            }
        },
        companyName: {
            property: "",
            errors: [],
            async isValid() {
                this.errors.length = 0
                let requiredError = this?.property?.length <= 0 || this?.property?.length > 3 ? undefined : "Company name must be more than 3 characters long!"
                requiredError && this?.errors?.push(requiredError)
                updateErrors('companyName', this?.errors)
                return this?.errors?.length === 0
            }
        },
        websiteUrl: {
            property: "",
            errors: [],
            async isValid() {
                this.errors.length = 0
                let websiteError =  this?.property?.length <= 0 || isValid.url(this?.property) ? undefined : "Wrong website format."
                websiteError && this?.errors?.push(websiteError)
                updateErrors('websiteUrl', this?.errors)
                return this?.errors?.length === 0
            }
        },
        inviteTeammates: {
            property: "",
            placeholder: "email@example.com",
            errors: [],
            listValue: [],
            async isValid() {
                this.errors.length = 0
                let emailError = this?.property?.length > 0 && isValid.email(this?.property) ? undefined : "Wrong email format."
                emailError && this?.errors?.push(emailError)
                let alreadExists = !emailError && this?.property?.length > 0 && this.listValue.length && this.listValue.some((invite: { email: string }) => invite.email === this?.property)
                alreadExists && this?.errors?.push("Email already in the list.")
                updateErrors('inviteTeammates', this?.errors)
                return this?.errors?.length === 0
            }
        }
    }

    const [teamFormState, setTeamFormState] = useState<ITeamFormState>({ ...formOriginalState })
    const [teamFormValid, setTeamFormValid] = useState<boolean>(false)
    const [invites, setInvites] = useState<{ email: string }[]>([]);
    const { store: { workspaceUsersFilters  } } = useWorkspaceUsersStore()
    const { setWorkspaceUsersFilters} = useWorkspaceUsersActions()

    useEffect(() => {
        if(workspace) setTeamFormState({
            ...teamFormState,
            teamName: {
                ...teamFormState.teamName, 
                property: workspace.name
            } 
        })
    }, [])

    useEffect(() => {
        if('sendInvites' in requestLoading 
           ) setSubmitLoading(requestLoading?.['sendInvites'])
        if('createWorkspace' in requestLoading 
            && ('sendInvites' in requestLoading && !requestLoading?.['sendInvites'])
        ) setSubmitLoading(requestLoading?.['createWorkspace'])

    }, [requestLoading]);

    const validateForm = useCallback(async() => {
        setTeamFormValid(false);
        const validForm = await teamFormState?.teamName.isValid() && await teamFormState?.companyName.isValid() && await teamFormState?.websiteUrl.isValid() || (invite ?? false);
        setTeamFormValid(validForm);
        return validForm;
    }, [teamFormState])

    const updateForm = useCallback((control: any, newValue: string) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], property: newValue.trim()} };
            return newState
        })
    }, [teamFormState]); 

    const updateErrors = useCallback((control: any, newValue: string[]) => {
        setTeamFormState(previous => {
            let newState = { ...previous, [control]: { ...previous[control], errors: [...newValue]} };
            return newState
        })
    }, [teamFormState]); 

    const onAddInvite = useCallback(async (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
            e.preventDefault();
			if (await teamFormState?.inviteTeammates?.isValid()) {
                let newInvites = [...invites, { email: teamFormState?.inviteTeammates?.property }]
                setInvites([...newInvites])
                setTeamFormState(previous => ({ ...previous, inviteTeammates: { ...previous.inviteTeammates, property: '', listValue: [...newInvites] } }))
            }	
		}
	}, [teamFormState]); 

    const onRemoveInvite = useCallback(async (removingInvite: string) => {
        const filteredInvites = invites.filter(invite => invite.email !== removingInvite)
        setInvites([...filteredInvites])
        setTeamFormState(previous => ({ ...previous, inviteTeammates: { ...previous.inviteTeammates, property: '', listValue: filteredInvites } }))
	}, [invites]); 

    const submitFormHandler = useCallback(async (e: FormEvent) => {
        e.preventDefault();
		const validForm = await validateForm();
        setSubmitLoading(true);
        if(validForm){
            const payload = {
                name: teamFormState.teamName.property,
                ...(teamFormState.companyName.property && { companyName: teamFormState.companyName.property }),
                ...(teamFormState.websiteUrl.property && { websiteUrl: teamFormState.companyName.property }),
                invites: invites || [],
                isPersonal: loginWorkspace ? true : false
            }
        if(invite) {
            await sendInvitesHandlers({invites: payload.invites})
            setWorkspaceUsersFilters({...workspaceUsersFilters})
        } else if(!workspace) {
            if(loginWorkspace) {
                await createAndLoginToWorkspaceHandler(payload)
            }
            else {
                await createWorkspaceHandler(payload)
            }
        } else {
            setWorkspace({...workspace, name: payload.name})
            await updateWorkspaceHandler({name: payload.name})
        }

        closeSidebar(SidebarTypes.WORKSPACE_CREATE)
        setSubmitLoading(false);

        } else setSubmitLoading(false);
	}, [teamFormState, workspaceUsersFilters]); 

    return (
        <StyledForm onSubmit={submitFormHandler}>
            {!invite ? (<>
                <FormControl $orientation="column" label="Team name" error={teamFormState?.teamName?.errors?.[0]}>
                    <Input 
                        prefilledValue={teamFormState?.teamName?.property} 
                        placeholder={teamFormState?.teamName?.placeholder} 
                        onChangeHandler={(value: string) => updateForm('teamName', value)} 
                        onBlurHandler={async (e: FocusEvent) => await validateForm()}
                    />
                </FormControl>
            </>) : null} 
            {(!workspace && !invite) ? (<>
                <FormControl $orientation="column" label="Company name" error={teamFormState?.companyName?.errors?.[0]}>
                    <Input 
                        prefilledValue={teamFormState?.companyName?.property} 
                        placeholder={teamFormState?.companyName?.placeholder} 
                        onChangeHandler={(value: string) => updateForm('companyName', value)}
                        onBlurHandler={async (e: FocusEvent) => await teamFormState?.companyName?.isValid() && await validateForm()}
                    />
                </FormControl>
                <FormControl $orientation="column" label="Company website url" error={teamFormState?.websiteUrl?.errors?.[0]}>
                    <Input 
                        prefilledValue={teamFormState?.websiteUrl?.property} 
                        placeholder={teamFormState?.websiteUrl?.placeholder} 
                        onChangeHandler={(value: string) => updateForm('websiteUrl', value)} 
                        onBlurHandler={async (e: FocusEvent) => await teamFormState?.websiteUrl?.isValid() && await validateForm()}
                    />
                </FormControl>
            </>) : null}
            {(!workspace || invite ) ? (<>
                <FormControl $orientation="column" label="Invite teammates" error={teamFormState?.inviteTeammates?.errors?.[0]}>
                    <Input 
                        description='press enter'
                        prefilledValue={teamFormState?.inviteTeammates?.property}
                        placeholder={teamFormState?.inviteTeammates?.placeholder}
                        onChangeHandler={(value: string) => updateForm('inviteTeammates', value)}
                        onKeyDownHandler={(e: KeyboardEvent) => onAddInvite(e)}/>
                </FormControl>
                {!!invites?.length && <InvitesContainer>
                    {invites.map((invite, index) => 
                        (<InvitationItemFragment key={index} invite={invite.email} removeInvite={(invite) => onRemoveInvite(invite)}/>))}
                </InvitesContainer>}
            </>) : null}
            <Button $type='blue' type="submit" disabled={invite && invites.length === 0 || !invite && teamFormState.teamName.property.length === 0} isLoading={submitLoading}>{!workspace ? 'Continue' : 'Update'}</Button>
        </StyledForm>
    )
}


const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const InvitesContainer = styled(List)`
    max-height: 200px;
    overflow-y: auto;
`

export default CreateWorkspaceForm;