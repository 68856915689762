import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
//import TabsNameInput from '../Inputs/TabsNameInput'
//import LDTooltip from 'components/LDTooltip/LDTooltip';
import Input from "../components/Input";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import DropdownItem from "../components/dropdown/DropdownItem";
import Checkbox from "../components/Checkbox";
import Row from "../components/Row";
import Text from "../components/Text";
import TabsView, { ITabProperties } from "../components/TabsView";
import TabsSearch from "../components/TabsSearch";

interface IAdvancedSearchFragmentProps {
    children: any;
    tabs: ITabProperties[];
    infoTitle: string;
    itemsLabel: string;
    resetDisabled?: boolean;
    searchByOption: any,
    overflow?: string;
    inputValue?: string;
    isCheckboxChecked: (e: any) => boolean;
    setSelectedTab: (e: any) => void;
    searchByValueHandler: (e: any) => void;
    resetSelectedValues: (e: any) => void;
    searchByOptionHandler: (e: any) => void;
}

const AdvancedSearchFragment: FunctionComponent<IAdvancedSearchFragmentProps> = ({
    tabs,
    infoTitle,
    itemsLabel,
    isCheckboxChecked,
    searchByValueHandler,
    setSelectedTab,
    resetDisabled,
    resetSelectedValues,
    children,
    overflow = "true",
    inputValue,
    searchByOptionHandler,
    searchByOption,
    ...props
}) => {

    return (<Wrapper>
        <DropdownMenu autoClose='outside' selectStyle title={
            <Row alignItems>
                <Input $noBorder placeholder="Search by..." onChangeHandler={(e: any) => {searchByValueHandler(e);}} value={inputValue}/>
            </Row>
               }
        >
            <TabsSearch 
                tabs={tabs} 
                infoTitle={infoTitle}
                resetSelectedValues={resetSelectedValues}
                setSelectedTab={(e) => setSelectedTab(e) }>
                <div style={{padding: '12px 0'}}><Text $label>{itemsLabel}</Text></div>
                {children.map((item: any) => {
                    return (
                        <DropdownItem key={item?.value} onClickHandler={() => searchByOptionHandler(item.value)}>
                            <Row alignItems gap="10px">
                                <Checkbox checked={isCheckboxChecked(item.value)} checkboxId={item.value} />
                                {item.label}
                            </Row>
                        </DropdownItem>
                    )
                })}
            </TabsSearch>
        </DropdownMenu>
    </Wrapper>)
};

const Wrapper = styled.div`
    & .actions-dropdown + div {
        width: 100%;
        padding: 16px 15px;
        transform: translate3d(0px, 36px, 0px) !important;
    }
    & .dropdown-item {
        padding: 0;
    }
`


export default AdvancedSearchFragment;
