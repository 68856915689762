import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from '../../../components/Row';
import ConnectionTable from '../components/connections/ConnectionTable';
import Column from '../../../components/Column';
import ConnectionControlBar from '../components/connections/ConnectionControlBar';
import { IConnection } from '../../../interfaces/models/connection';

interface IConnectionSectionProps { }

const ConnectionSection: FunctionComponent<IConnectionSectionProps> = () => {

    const [selectedConnections, setSelectedConnections] = useState<IConnection[]>([]);
    const [selectedAllConnections, setSelectedAllConnections] = useState<boolean>(false);

    const handleDataChange = (selectedConnections: IConnection[]) => {
        setSelectedConnections(selectedConnections);
    };

    const handleAllSelectedChange = (isAllSelected: boolean) => {
        setSelectedAllConnections(isAllSelected);
    };

    return <Container>
        <Column>
            <ConnectionControlBar selectedConnections={selectedConnections} selectedAllConnections={selectedAllConnections} />
            <ConnectionTable selectedConnectionsChange={handleDataChange} selectedConnections={selectedConnections} selectedAllConnectionsChange={handleAllSelectedChange} />
        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`

const ButtonRow = styled(Row)`
    justify-content: center;
`

export default ConnectionSection
