import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { AppRoutes } from '../interfaces/store/appStore';
import { useAppStore } from '../stores/application/useAppStore';
import { Navigate, Outlet } from 'react-router-dom';
import { useInterval } from './IntervalContext';
import { useAuthStore } from '../stores/auth/useAuthStore';
import moment from 'moment';
import { useAuthHandlers } from '../services/actions/auth/useAuthHandlers';

interface IRouteGuardProps { }

const RouteGuard: FunctionComponent<IRouteGuardProps> = () => {
    const { store: { onboardingStep } } = useAppStore()
    const { store: { user } } = useAuthStore()
    const { stopInterval, startInterval } = useInterval();
    const { getUserHandler } = useAuthHandlers();
    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

    useEffect(() => {
        if(user?.isSynced){
            stopInterval()
        }
        if(!user?.isSynced && moment().isBefore(moment(lastSyncAt).add(20, 'minutes'))){
            startInterval(async () => getUserHandler(), 300000)
        }
      }, [user]);

    return <>
        {onboardingStep >= 0 ?
            <Navigate to={AppRoutes.PROCESSING}/> :
            <Outlet /> }
    </>
}

export default RouteGuard
