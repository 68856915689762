
import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface IFlexStyles {
    flexColumn?: boolean,
    spaceBetween?: boolean,
    justifyCenter?: boolean,
    alignItems?: boolean,
    flexWrap?: boolean
}

interface IRowProps extends IFlexStyles {
    children?: ReactNode,
    className?: string,
    gap?: string
 }

const Row: FunctionComponent<IRowProps> = ({children, ...props}) => {
    return <Container {...props}>{children}</Container>
}

const Container = styled.div<IRowProps>`
    display: flex;
    flex-direction: row;
    ${({ gap }) => gap && `gap: ${gap};`}
    ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}
    ${({ justifyCenter }) => justifyCenter && 'justify-content: center;'}
    ${({ alignItems }) => alignItems && 'align-items: center;'}
    ${({ flexWrap }) => flexWrap && 'flex-wrap: wrap;'}
`;

export default Row