import { FunctionComponent } from "react"
import { DefaultTheme, useTheme } from "styled-components"
import { styles } from "../../styles/themes/style";
import ReactSelect, { Props, StylesConfig, components as component } from "react-select";

interface IStyledSelectProps extends Props {
    $components?: boolean
    maxHeight?: number,
    isMulti?: boolean
}

 /**
  * Use Select as the form control, search select, mutli select etc.
  */
const Select: FunctionComponent<IStyledSelectProps> = ({ $components, maxHeight, isMulti, ...props }) => {
    const theme: DefaultTheme = useTheme();
    //has value can be sent, so it is better resolved
    let customStyles: StylesConfig = {
        control: (base, props) => {
            return {
                 ...base,
                display: 'flex',
                alignItems: 'center',
                height: isMulti ? 'auto' : 34,
                minHeight: 34,
                boxSizing: 'border-box',
                padding: '0',
                border: `2px solid ${theme.quinaryColor}`,
                cursor: props.selectProps.isDisabled ? 'default' : 'pointer',
                boxShadow: 'none',

                '& div p': {
                    margin: '0',
                    height: '24px'
                },
                '&:hover': {
                    boxShadow: 'none',
                    border: `2px solid ${props.selectProps.isDisabled ? styles.colors.black200 : styles.colors.primary600}`
                },
                '&:active': {
                    boxShadow: 'none',
                    border: `2px solid ${props.selectProps.isDisabled ? styles.colors.black200 : styles.colors.primary600}`
                }
            }
        },
        container: () => ({
            width: '100%',
            display: 'inline-block',
            ...(theme.primary ? {} : { border: `2px solid ${styles.colors.darkblack500}` }),

        }),
        menu: (base, props) => {
            return {
                backgroundColor: theme.secondaryColor,
                borderRadius: '3px',
                marginTop: '5px',
                padding: 0,
                boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.1)',
                position: 'absolute',
                width: '100%',
                zIndex: 1004,
                cursor: props.selectProps.isDisabled ? 'none' : 'pointer',
                ...maxHeight && {
                    maxHeight: `${maxHeight}px`,
                    overflowY: 'auto',
                }
            }
        },
        menuList: (base, props) => {
            return {
                ...base,
                height: '100%'
            }
        },
        dropdownIndicator: (base, props) => {
            return {
                ...base,
                padding: '0px',
                marginRight: '6px',
                color: styles.colors.black300,
                transition: 'all .2s ease',
                transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,

                '&:hover': {
                    color: props.selectProps.isDisabled ? styles.colors.black300 : styles.colors.primary600
                }
            }
        },
        valueContainer: (base, props) => {
            return {
                height: '30px',
                padding: '0 6px',
                display: 'flex',
                alignItems: 'center'
            }
        },
        indicatorsContainer: (base, props) => {
            return {
                ...base,
                alignSelf: 'center',
                height: '20px',
                width: '20px',
                padding: '0px',
                marginRight: '6px'
            }
        },
        option: (base, props) => {
            return {
                textAlign: 'left',
                fontFamily: 'NunitoSans',
                fontSize: '14px',
                fontWeight: '400',
                color: theme.primaryColor,
                padding: '6px 20px',
                cursor: 'pointer',
    
                '&:hover': {
                    color: styles.colors.primary600,
                    backgroundColor: theme.senaryColor,
                }
            }
        },
        placeholder: () => ({
            lineHeight: '30px',
            fontFamily: 'NunitoSans',
            fontSize: '14px',
            color: styles.colors.black300
        }),
        singleValue: (base, props) => {
            return {
                ...base,
                color: props.selectProps.isDisabled ? styles.colors.black300 : theme.primaryColor,
                alignItems: 'center',
                fontFamily: 'NunitoSans',
                fontSize: '14px',
                lineHeight: '30px',
                backgroundColor: 'transparent',
            }
        }
    }

    if(isMulti) {
        customStyles = {
            ...customStyles,
            valueContainer: (base, props) => {
                return {
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    height: '100%', 
                    padding: '8px 10px'
                }
            }
        }
    }

    const SingleValue = (props: any) => (
        <component.SingleValue {...props}>
            {props.data.component}
        </component.SingleValue>
    )

    const Option = (props: any) => (
        <component.Option {...props}>
            {props.data.component}
        </component.Option>
    )
     
    return (
        <ReactSelect
            styles={customStyles}
            isMulti={isMulti}
            components={{
                IndicatorSeparator: () => null,

                ...$components ? {
                    SingleValue,
                    Option
                } : null
            }}
            {...props}
        />
    )
}

export default Select