import { forwardRef, FunctionComponent, ReactNode, useRef } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"
import TooltipWrapper from "./TooltipWrapper";

interface IStyledTextProps {
    ref?: React.Ref<HTMLDivElement>
    $bold?: boolean,
    $black?: boolean,
    $white?: boolean,
    $red?: boolean,
    $green?: boolean,
    $active?: boolean,
    $faded?: boolean,
    $lighter?: boolean,
    $paragraph1?: boolean,
    $paragraph3?: boolean,
    $heading1?: boolean,
    $heading2?: boolean,
    $heading3?: boolean,
    $heading4?: boolean,
    $heading5?: boolean,
    $heading6?: boolean,
    $label?: boolean,
    $description?: boolean,
    $placeholder?: boolean,
    $inheritColor?: boolean,
    $capitalize?: boolean
    $capitalizeAll?: boolean
    $breakWord?: boolean
    $ellipsis?: string
    className?: string
}

export interface ITextProps extends IStyledTextProps {
    children?: ReactNode,
    showTooltip?: boolean,
    tooltipMessage?: string
}

const Text = forwardRef<HTMLDivElement, ITextProps>(({ children, showTooltip = false, tooltipMessage, ...props }, ref) => {

    return (
        <TooltipWrapper tooltipText={tooltipMessage ? tooltipMessage : typeof children === "string" && showTooltip ? children : ''}>
            <StyledParagraph ref={ref} {...props} >
                {children}
            </StyledParagraph>
        </TooltipWrapper>
    )
})
const StyledParagraph = styled.span<ITextProps>`
    font-family: NunitoSans;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme: { primaryColor } }) => primaryColor};
    display: flex;
    gap: 4px;


    /* Colors */

    ${({ $black, theme: { primaryColor } }) => $black && `color: ${primaryColor};`}
    ${({ $white }) => $white && `color: ${styles.colors.white};`}
    ${({ $red }) => $red && `color: ${styles.colors.red600};`}
    ${({ $green }) => $green && `color: ${styles.colors.green600};`}
    ${({ $placeholder }) => $placeholder && `color: ${styles.colors.black300};`}
    ${({ $active }) => $active && `color: ${styles.colors.primary600};`}
    ${({ $lighter, theme: { nonaryColor } }) => $lighter && `color: ${nonaryColor};`}
    ${({ $faded, theme: { quaternaryColor } }) => $faded && `color: ${quaternaryColor};`}

    /* Paragraphs */
    ${({ $paragraph1 }) => $paragraph1 && `
        font-size: 16px;
        line-height: 24px;
    `}
    ${({ $paragraph3 }) => $paragraph3 && `
        font-size: 12px;
        line-height: 18px;
    `}

    /* Headings */
    ${({ $heading1 }) => $heading1 && `
        font-size: 36px;
        line-height: 40px;
    `}
    ${({ $heading2 }) => $heading2 && `
        font-size: 28px;
        line-height: 32px;
        font-weight: bold;
    `}
    ${({ $heading3 }) => $heading3 && `
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
    `}
    ${({ $heading4 }) => $heading4 && `
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    `}
    ${({ $heading5 }) => $heading5 && `
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
    `}
    ${({ $heading6 }) => $heading6 && `
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
    `}
    ${({ $label }) => $label && `
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        font-weight: bold;
    `}
    ${({ $description }) => $description && `
        font-size: 11px;
        line-height: 16px;
    `}
    ${({ $bold }) => $bold && 'font-weight: bold;'}
    ${({ $capitalize }) => $capitalize && 'text-transform: capitalize;'}
    ${({ $capitalizeAll }) => $capitalizeAll && 'text-transform: uppercase;'}
    ${({ $inheritColor }) => $inheritColor && `color: inherit;`}

    ${({ $breakWord }) => $breakWord && 'word-break: break-all;'}

    ${({ $ellipsis }) => $ellipsis && ($ellipsis === '0' ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        word-break: break-all;
    ` : ($ellipsis === '1' || $ellipsis === '2' || $ellipsis === '3' || $ellipsis === '4' || $ellipsis === '5' || $ellipsis === '6') && `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${$ellipsis};
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    `)}

    & .ld-tag {
        background-color: #faf4ba;
        color: #5964ff;
    }
`
export default Text